import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { VehicleUtil } from 'wni-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import {
    RecommendInputComponent, ValidationIssuesComponent,
} from 'wni-components-platform-react';

import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import AddressInputComponent from '../../../components/AddressInputComponent/AddressInputComponent';
import BankSearchComponent from './bankSearchComponent/BankSearchComponent';
import messages from '../AdditionalInterestComponent.message';
import metadata from './AdditionalInterestDetailPopup.metadata.json5';
import styles from './AdditionalInterestDetailPopup.module.scss';

import { Button } from '@jutro/legacy/components';

function BaseAdditionalInterestDetailComponent(props) {
    const {
        title,
        size,
        actionBtnLabel,
        cancelBtnLabel,
        newSearchBtnLabel,
        additionalInterestVM,
        emptyVM,
        viewModelService,
        accountNumber,
        accountContacts,
        // accountHolderBirthday,
        // licenseState,
        type,
        readOnly,
        isOpen,
        onResolve,
        onReject,
        getAdditionalInterestTypeOptions,
        isPopupMode,
        workflowType,
        additionalInterestTypeValueChangeFn,
        customActionFooterFn,
        showLoanNumber,
        useAuthenticationData
    } = props;
    const [getAdditionalInterestVM, updateAdditionalInterestVM] = useState(additionalInterestVM);
    const { isComponentValid, onValidate, registerComponentValidation, invalidFields } = useValidation('BaseAdditionalInterestDetailComponent');
    const [showErrors, updateShowErrors] = useState(false);
    // const [recommendOptions, updateRecommendOptions] = useState([]);
    const [disableContactDetail, updateDisableContactDetail] = useState(type === 'edit');
    const [selectedContact, updateSelectedContact] = useState();
    const [additionalInterestTypeOptions, updateAdditionalInterestTypeOptions] = useState([]);
    // const [isNewContact, updateIsNewContact] = useState(false);
    const [selectedTrustContact, updateSelectedTrustContact] = useState(null);
    const [newAdding, updateNewAdding] = useState(true);
    const [showBankDetail, updateShowBankDetail] = useState(false);
    const [selectRow, updateSelectRow] = useState({});
    const [disableAddressDetail, updateDisableAddressDetail] = useState(false);
    const translator = useTranslator();
    const [selectedContactNewOptionCalled, updateSelectedContactNewOptionCalled] = useState(false);
    const [selectedTrustNewOptionCalled, updateSelectedTrustNewOptionCalled] = useState(false);
    const [selectedBankNewOptionCalled, updateSelectedBankNewOptionCalled] = useState(false);
    const [disableCompanyTypeOptions, updateCompanyTypeOption] = useState(true);

    const contactOptions = accountContacts
        .filter((c) => c.subtype === 'Person')
        .map((c) => {
            return {
                code: c.publicID,
                name: c.displayName,
            };
        })
        .concat({ code: 'newOption', name: 'New' });

    // filter trust contacts
    const trustContactOptions = accountContacts
        .filter((c) => c.subtype === 'Company')
        .filter((c) => c.companyType_Ext === 'IndividualOrTrust')
        .map((c) => {
            return {
                code: c.publicID,
                name: c.displayName,
            };
        })
        .concat({ code: 'newOption', name: 'New' });

    const {
        policyAdditionalInterest: {
            companyType_Ext: vmCompanyTypeExt,
            primaryAddress,
            contactName
        }
    } = getAdditionalInterestVM.value;

    const [companyType, updateCompanyType] = useState(vmCompanyTypeExt);

    // Person
    const handlePersonOnBlur = useCallback(async (contact) => {
        const publicID = _.get(contact, 'publicID');
        const isPerson = true;
        if (isPerson) {
            const isCompany = false;
            const isBank = false;
            const isTrust = false;
            // call service
            const options = await getAdditionalInterestTypeOptions({
                publicID,
                isPerson,
                isCompany,
                isBank,
                isTrust
            });
            updateAdditionalInterestTypeOptions(options);
        }
    }, [getAdditionalInterestTypeOptions]);

    // bank
    const handleContactNameBlur = useCallback(async (item) => {
        const isCompany = true;
        const isBank = companyType === 'Bank';
        const isTrust = companyType === 'IndividualOrTrust';
        if (isCompany && !_.isNil(contactName) && !_.isNil(companyType)) {
            const publicID = _.get(item, 'publicID');
            const isPerson = false;
            // call service
            const options = await getAdditionalInterestTypeOptions({
                publicID,
                isPerson,
                isCompany,
                isBank,
                isTrust
            });
            updateAdditionalInterestTypeOptions(options);
        }
    }, [companyType, contactName, getAdditionalInterestTypeOptions]);

    useEffect(() => {
        const contact = _.get(getAdditionalInterestVM, 'value.policyAdditionalInterest');
        const subtype = _.get(contact, 'subtype');
        if (subtype === 'Person' && (_.isNil(additionalInterestTypeOptions) || additionalInterestTypeOptions.length < 1)) {
            handlePersonOnBlur(null);
        }
    }, []);

    useEffect(() => {
        const contact = _.get(getAdditionalInterestVM, 'value.policyAdditionalInterest');
        const publicID = _.get(contact, 'publicID');
        const subtype = _.get(contact, 'subtype');
        const firstName = _.get(contact, 'firstName');
        const isTrust = companyType === 'IndividualOrTrust';
        if (_.isNil(publicID)) {
            // updateIsNewContact(true);
            // New addde but not saved in db
            if (subtype === 'Person') {
                // Set companyType_Ext to Person
                updateCompanyType('Person');
                if (!_.isNil(firstName)) {
                    updateNewAdding(false);
                    updateSelectedContact('newOption');
                    if (!selectedContactNewOptionCalled) {
                        handlePersonOnBlur(contact);
                        updateSelectedContactNewOptionCalled(true);
                    }
                }
            } else if (!_.isNil(contactName)) {
                updateNewAdding(false);
                if (isTrust) {
                    updateSelectedTrustContact('newOption');
                    if (!selectedTrustNewOptionCalled) {
                        handleContactNameBlur(contact);
                        updateSelectedTrustNewOptionCalled(true);
                    }
                } else {
                    // show bank detail
                    updateShowBankDetail(true);
                    if (!selectedBankNewOptionCalled) {
                        handleContactNameBlur(contact);
                        updateSelectedBankNewOptionCalled(true);
                    }
                }
            }
        } else {
            // updateIsNewContact(false);
            updateNewAdding(false);
            if (subtype === 'Person') {
                updateSelectedContact(publicID);
                // Set companyType_Ext to Person
                updateCompanyType('Person');
                handlePersonOnBlur(contact);
                updateSelectedContactNewOptionCalled(false);
            } else {
                if (isTrust) {
                    updateSelectedTrustContact(publicID);
                    updateSelectedTrustNewOptionCalled(false);
                } else {
                    // show bank detail
                    updateShowBankDetail(true);
                    // disable address edit
                    updateDisableAddressDetail(true);
                    updateSelectedBankNewOptionCalled(false);
                }
                handleContactNameBlur(contact);
            }
        }
    }, [companyType, contactName, getAdditionalInterestVM,
        handleContactNameBlur, handlePersonOnBlur,
        selectedContactNewOptionCalled, selectedTrustNewOptionCalled, selectedBankNewOptionCalled]);

    // disable phone validation for external data, also works for internal data
    _.set(getAdditionalInterestVM, 'policyAdditionalInterest.phoneRequired_Ext', false);

    const writeValue = useCallback(
        (value, path) => {
            if (readOnly) {
                return;
            }
            const newAdditionalInterestVM = viewModelService.clone(getAdditionalInterestVM);
            _.set(newAdditionalInterestVM, path, value);
            updateAdditionalInterestVM(newAdditionalInterestVM);
        },
        [getAdditionalInterestVM, readOnly, viewModelService]
    );

    const isContactNameValid = useCallback(() => {
        const subtype = _.get(getAdditionalInterestVM, 'value.policyAdditionalInterest.subtype');
        if (subtype === 'Company') {
            return !_.isEmpty(contactName);
        }
        return true;
    }, [getAdditionalInterestVM, contactName]);

    const isAddressValid = useCallback(() => {
        const {
            primaryAddress: {
                pobox_Ext: pobox,
                addressLine1,
                postalCode,
                city,
                state
            }
        } = getAdditionalInterestVM.policyAdditionalInterest.value;

        const valid = (postalCode && city && state);
        if (pobox) {
            return !!(valid);
        }
        return !!(valid && addressLine1);
    }, [getAdditionalInterestVM.policyAdditionalInterest.value]);

    const isPopupValid = useCallback(() => {
        return isContactNameValid() && isAddressValid();
    }, [isAddressValid, isContactNameValid]);

    useEffect(() => {
        registerComponentValidation(isPopupValid);
    }, [registerComponentValidation, isPopupValid]);

    const onAddressChange = (value, path) => {
        writeValue(value, `policyAdditionalInterest.${path}`);
    };
    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
        },
        [updateShowErrors]
    );
    const handleSave = useCallback(
        async () => {
            if (!isComponentValid) {
                handleValidation();
                return false;
            }

            if (_.get(getAdditionalInterestVM, 'policyAdditionalInterest.phoneRequired_Ext')) {
                _.set(getAdditionalInterestVM, 'policyAdditionalInterest.phoneRequired_Ext', false);
            }
            return onResolve(getAdditionalInterestVM);
        },
        [isComponentValid, getAdditionalInterestVM, onResolve, handleValidation]
    );

    const subtype = _.get(getAdditionalInterestVM, 'policyAdditionalInterest.subtype.value');
    const interestType = _.get(getAdditionalInterestVM, 'type.value.code');
    const relationshipToInsured = _.get(getAdditionalInterestVM, 'relationshipToInsured_Ext.value.code');

    const filterInterestTypes = useCallback(() => {
        if (_.isNil(additionalInterestTypeOptions) || additionalInterestTypeOptions.length < 1) {
            return [];
        }
        const availableInterestTypes = _.get(getAdditionalInterestVM, 'type.aspects.availableValues', []);
        const options = availableInterestTypes
            .filter((option) => additionalInterestTypeOptions.includes(option.code)
                || option.code === _.get(getAdditionalInterestVM, 'value.type')
            )
            .map((role) => {
                return {
                    code: role.code,
                    name: {
                        id: role.name
                    }
                };
            });
        return options;
    }, [additionalInterestTypeOptions, getAdditionalInterestVM]);

    const filterRelationshipToInsured = useCallback(() => {
        const relationshipToInsuredValues = _.get(getAdditionalInterestVM, 'relationshipToInsured_Ext.aspects.availableValues', []);
        return relationshipToInsuredValues
            .filter((option) => VehicleUtil.checkPARelationshipToInsuredCodeIsVisble(option.code))
            .map((role) => {
                return {
                    code: role.code,
                    name: {
                        id: role.name
                    }
                };
            });
    }, [getAdditionalInterestVM]);

    const setDefaultValueForTrustInterestType = useCallback(() => {
        if (companyType === 'IndividualOrTrust') {
            _.set(getAdditionalInterestVM, 'type', 'CERTIFICATEOFINSURANCETRUST_Ext');
        }
    }, [companyType, getAdditionalInterestVM]);

    // reset Country To Default US
    const resetCountryToDefault = useCallback((vm) => {
        _.set(vm, 'value.policyAdditionalInterest.primaryAddress.country', 'US');
    }, []);

    const handleContactChange = useCallback((code) => {
        if (code === undefined) {
            return;
        }
        updateSelectedContact(code);
        if (code === 'newOption') {
            const newVM = viewModelService.clone(emptyVM);
            _.set(newVM, 'value.policyAdditionalInterest.subtype', 'Person');
            // set companyType_Ext value Person to enable Person container
            _.set(newVM, 'value.policyAdditionalInterest.companyType_Ext', 'Person');
            // set addressType default value home
            _.set(newVM, 'value.policyAdditionalInterest.primaryAddress.addressType', 'home');
            resetCountryToDefault(newVM);
            updateAdditionalInterestVM(newVM);
            updateDisableContactDetail(false);
            return;
        }
        const contact = _.find(accountContacts, ['publicID', code]);
        writeValue(contact, 'policyAdditionalInterest');
        updateDisableContactDetail(true);
    }, [accountContacts, emptyVM, resetCountryToDefault, viewModelService, writeValue]);

    const handleTrustContactChange = useCallback((code) => {
        if (code === undefined) {
            return;
        }
        updateSelectedTrustContact(code);
        if (code === 'newOption') {
            const newVM = viewModelService.clone(emptyVM);
            _.set(newVM, 'value.policyAdditionalInterest.subtype', 'Company');
            // set companyType_Ext value Trust to enable Company container
            _.set(newVM, 'value.policyAdditionalInterest.companyType_Ext', 'IndividualOrTrust');
            // set addressType default value home
            _.set(newVM, 'value.policyAdditionalInterest.primaryAddress.addressType', 'home');
            resetCountryToDefault(newVM);
            updateAdditionalInterestVM(newVM);
            // update the interest type options
            handleContactNameBlur(null);
            // enable contact details edit
            updateDisableContactDetail(false);
            return;
        }
        const contact = _.find(accountContacts, ['publicID', code]);
        writeValue(contact, 'policyAdditionalInterest');
        // update the interest type options
        handleContactNameBlur(contact);
        // disable contact details edit
        updateDisableContactDetail(true);
    }, [accountContacts, emptyVM, handleContactNameBlur,
        resetCountryToDefault, viewModelService, writeValue]);

    const updateSubType = useCallback((value) => {
        updateCompanyTypeOption(value !== null);
        // remove errors
        updateShowErrors(false);
        // enable contact details edit
        updateDisableContactDetail(false);
        // enable address details edit
        updateDisableAddressDetail(false);
        // value not change, return
        if (companyType === value) {
            return true;
        }
        // clear interest type options
        updateAdditionalInterestTypeOptions([]);
        // update value
        updateCompanyType(value);
        // clear values
        const newVM = viewModelService.clone(emptyVM);
        resetCountryToDefault(newVM);
        _.set(newVM, 'value.policyAdditionalInterest.companyType_Ext', value);
        if (value === 'Person') {
            _.set(newVM, 'value.policyAdditionalInterest.subtype', 'Person');
            updateAdditionalInterestVM(newVM);
            handlePersonOnBlur(null);
            // reset existing Person contact
            updateSelectedContact(null);
        } else {
            _.set(newVM, 'value.policyAdditionalInterest.subtype', 'Company');
            updateAdditionalInterestVM(newVM);
            // reset existing Trust contact
            updateSelectedTrustContact(null);
        }
        // bank should show search first
        if (value !== 'Bank') {
            updateNewAdding(false);
        } else {
            updateNewAdding(true);
            updateShowBankDetail(false);
        }
        return true;
    }, [companyType, emptyVM, handlePersonOnBlur, resetCountryToDefault, viewModelService]);

    const handleSelectBank = useCallback(() => {
        // set default valut for trust
        setDefaultValueForTrustInterestType();
        writeValue(selectRow, 'policyAdditionalInterest');
        // update the interest type options
        handleContactNameBlur(selectRow);
        // disable contact details edit
        updateDisableContactDetail(true);
        // show bank detail container
        updateShowBankDetail(true);
        // disable address edit
        updateDisableAddressDetail(true);
    }, [handleContactNameBlur, selectRow, setDefaultValueForTrustInterestType, writeValue]);

    const showBankSearch = useCallback(() => {
        // reset selectRow
        updateSelectRow({});
        // show bank search
        updateShowBankDetail(false);
        updateNewAdding(true);
        // reset subType value
        updateSubType('Bank');
    }, [updateSubType]);

    const addNewBankFn = useCallback((bankSearchCritieraVM) => {
        // remove errors
        updateShowErrors(false);
        // show bank detail
        updateShowBankDetail(true);
        // enable address details edit
        updateDisableAddressDetail(false);
        // clear values
        const newVM = viewModelService.clone(emptyVM);
        _.set(newVM, 'value.policyAdditionalInterest.companyType_Ext', 'Bank');
        _.set(newVM, 'value.policyAdditionalInterest.subtype', 'Company');
        _.set(newVM, 'value.policyAdditionalInterest.contactName', _.get(bankSearchCritieraVM, 'value.bankName'));
        _.set(newVM, 'value.policyAdditionalInterest.primaryAddress', _.get(bankSearchCritieraVM, 'value.primaryAddress'));
        if (_.isEmpty(_.get(bankSearchCritieraVM, 'value.country'))) {
            resetCountryToDefault(newVM);
        }
        updateAdditionalInterestVM(newVM);
    }, [emptyVM, resetCountryToDefault, viewModelService]);

    const customFooter = useCallback(() => {
        // if (readOnly) {
        //     return null;
        // }
        if (companyType === 'Bank') {
            let btns = null;
            if (showBankDetail) {
                btns = (
                    <>
                        <Button disabled={readOnly} onClick={showBankSearch} type="filled">{newSearchBtnLabel}</Button>
                        <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                        <Button disabled={_.isEmpty(companyType) || readOnly} onClick={handleSave} type="outlined">{actionBtnLabel}</Button>
                    </>
                );
            } else {
                btns = (
                    <>
                        <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                        <Button disabled={_.isEmpty(selectRow) || readOnly} onClick={handleSelectBank} type="outlined">{actionBtnLabel}</Button>
                    </>
                );
            }
            return btns;
        }
        return (
            <>
                <Button onClick={onReject} type="outlined">{cancelBtnLabel}</Button>
                <Button disabled={_.isEmpty(companyType) || readOnly} onClick={handleSave} type="outlined">{actionBtnLabel}</Button>
            </>
        );
    }, [readOnly, companyType, onReject, cancelBtnLabel,
        handleSave, actionBtnLabel, showBankDetail,
        showBankSearch, newSearchBtnLabel, selectRow, handleSelectBank]);

    const resolvers = {
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            recommendinputcomponent: RecommendInputComponent,
            addressinputcomponent: AddressInputComponent,
            bankSearchComponent: BankSearchComponent
        },
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@all': {
            readOnly,
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            disabled: disableContactDetail
        },
        bankSearchComponent: {
            viewModelService,
            visible: companyType === 'Bank' && !showBankDetail,
            updateShowBankDetail,
            selectRow,
            updateSelectRow,
            addNewBankFn,
            useAuthenticationData
        },
        personCompanybinaryswitch: {
            visible: false
        },
        companyContainer: {
            visible: subtype === 'Company'
        },
        personalContainer: {
            visible: subtype === 'Person'
        },
        typeSwitch: {
            onValueChange: updateSubType,
            label: translator(messages.addAdditionalInterestType),
            visible: newAdding && !showBankDetail,
            disabled: false,
            value: companyType
        },
        additionalInterestDetailContainer: {
            visible: !newAdding || showBankDetail
        },
        additionalInterestPersonContact: {
            value: selectedContact,
            onValueChange: handleContactChange,
            availableValues: contactOptions,
            disabled: false
        },
        additionalInterestRelationshipToInsured: {
            visible: (interestType === 'JOINTOWNERSHIP_Ext' || interestType === 'ADDITIONALINSURED_Ext') && subtype === 'Person',
            availableValues: filterRelationshipToInsured(),
            disabled: false
        },
        additionalInterestExplain: {
            visible: (interestType === 'JOINTOWNERSHIP_Ext' || interestType === 'ADDITIONALINSURED_Ext') && relationshipToInsured === 'Ot',
            disabled: false,
            maxLength: 50
        },
        additionalInterestSendBill: {
            visible: companyType === 'Bank' && !workflowType.ClassicalWorkflow,
            disabled: interestType !== 'FIRSTMORTGAGEE',
            value: _.get(getAdditionalInterestVM, 'value.sendBill_Ext') || false,
            onValueChange: (newValue) => {
                writeValue(newValue, 'sendBill_Ext');
            }
        },
        additionalInterestInterestType: {
            availableValues: filterInterestTypes(),
            disabled: false,
            value: _.get(getAdditionalInterestVM, 'value.type'),
            onValueChange: (newValue) => {
                if (!workflowType.ClassicalWorkflow) {
                    additionalInterestTypeValueChangeFn(
                        newValue,
                        viewModelService,
                        getAdditionalInterestVM,
                        updateAdditionalInterestVM,
                        writeValue
                    )
                } else {
                    writeValue(newValue, 'type');
                }
            }
        },
        companyTypeSwitchForPerson: {
            value: companyType,
            onValueChange: updateSubType,
            readOnly: disableCompanyTypeOptions,
            label: translator(messages.addAdditionalInterestType),
            disabled: false
        },
        companyTypeSwitch: {
            value: companyType,
            onValueChange: updateSubType,
            label: translator(messages.addAdditionalInterestType),
            readOnly:disableCompanyTypeOptions,
            disabled: false
        },
        additionalInterestBankContact: {
            visible: companyType === 'Bank',
            label: translator(messages.paAddAdditionalInterestBankName)
        },
        additionalInterestTrustContact: {
            visible: companyType !== 'Bank',
            value: selectedTrustContact,
            onValueChange: handleTrustContactChange,
            availableValues: trustContactOptions,
            label: translator(messages.paAddAdditionalInterestTrust),
            disabled: false
        },
        additionalInterestTrustContactName: {
            visible: companyType !== 'Bank',
            label: translator(messages.paAddAdditionalInterestTrustName)
        },
        additionalInterestLoanNumber: {
            disabled: false,
            visible: showLoanNumber
        },
        primaryAddressContainer: {
            model: _.get(getAdditionalInterestVM, 'policyAdditionalInterest'),
            dataPath: 'primaryAddress',
            onAddressChange: onAddressChange,
            showErrors,
            unAutoFilled: {
                addressLine1: true,
                postalCode: false
            },
            readOnly: disableAddressDetail || readOnly,
            unRequiredField: {
                county: true
            },
            onValidate,
            hideFieldType: {
                addressType: true
            },
            defaultCountryRequired: false,
            useAuthenticationData
        }
    };

    if (isPopupMode) {
        return (
            <ModalNext isOpen={isOpen} className={size}>
                <ModalHeader title={title} />
                <ModalBody id="additionalInterestDetailModal">
                    <ViewModelForm
                        uiProps={metadata.componentContent}
                        model={getAdditionalInterestVM}
                        onValidationChange={onValidate}
                        onValueChange={writeValue}
                        overrideProps={overrideProps}
                        componentMap={resolvers.resolveComponentMap}
                        classNameMap={resolvers.resolveClassNameMap}
                        showErrors={showErrors}
                    />
                </ModalBody>
                <ModalFooter>
                    { customFooter() }
                </ModalFooter>
            </ModalNext>
        )
    }
    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={getAdditionalInterestVM}
                onValidationChange={onValidate}
                onValueChange={writeValue}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            { !workflowType.ClassicalWorkflow ?
                customActionFooterFn(companyType, showBankDetail, readOnly, showBankSearch,
                    newSearchBtnLabel, onReject, cancelBtnLabel, handleSave, actionBtnLabel,
                    selectRow, handleSelectBank, getAdditionalInterestVM, isComponentValid,
                    handleValidation)
                : customFooter()
            }
        </div>
    );
}

BaseAdditionalInterestDetailComponent.propTypes = {
    additionalInterestVM: PropTypes.shape({}).isRequired,
    emptyVM: PropTypes.shape({}),
    accountNumber: PropTypes.string.isRequired,
    accountContacts: PropTypes.shape([]),
    accountHolderBirthday: PropTypes.shape({}).isRequired,
    // authHeader: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    // licenseState: PropTypes.string,
    type: PropTypes.string,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    newSearchBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func,
        create: PropTypes.func,
    }).isRequired,
    readOnly: PropTypes.bool,
    getAdditionalInterestTypeOptions: PropTypes.func,
    showBottomButtons: PropTypes.bool,
    saveAdditionalInterestFn: PropTypes.func,
    cancelAddAdditionalInterestFn: PropTypes.func,
    isPopupMode: PropTypes.bool,
    workflowType: PropTypes.shape({
        ClassicalWorkflow: PropTypes.bool
    }),
    showLoanNumber: PropTypes.bool
};

BaseAdditionalInterestDetailComponent.defaultProps = {
    // licenseState: '',
    size: 'md',
    readOnly: false,
    type: null,
    accountContacts: [],
    emptyVM: {},
    getAdditionalInterestTypeOptions: _.noop,
    showBottomButtons: false,
    saveAdditionalInterestFn: _.noop,
    cancelAddAdditionalInterestFn: _.noop,
    isPopupMode: false,
    workflowType: {
        ClassicalWorkflow: true
    },
    showLoanNumber: true
};

export default BaseAdditionalInterestDetailComponent;
