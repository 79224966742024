import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
// import { BreakpointTrackerContext } from '@jutro/layout';
// import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { IMPolicyDetailsService } from 'wni-capability-quoteandbind-im';
import {
    ValidationIssueUtil,
    WindowUtil,
    QuoteUtil,
    WniPNIUtil,
    WniProductsUtil,
    WniDateUtil
} from 'wni-portals-util-js';
import { useProductsData } from 'wni-portals-util-react';
import {
    AgencyAndProducerInfoComponent,
    PrimaryNamedInsuredInputPopup,
    PolicyDBAComponent,
    ANIComponent
} from 'wni-capability-gateway-react';
import moment from 'moment';
import { WizardErrorContext } from 'wni-portals-wizard-react';
import { BRCLMessages }from 'wni-platform-translations';
import WizardPage from '../../templates/IMWizardPage';

import styles from './IMPolicyDetailsPage.module.scss';
import metadata from './IMPolicyDetailsPage.metadata.json5';

const {
    IM_PRODUCT_CODE,
    getLobName
} = WniProductsUtil;
function IMPolicyDetailsPage(props) {
    const wizardErrorContext = useContext(WizardErrorContext);
    const modalApi = useWniModal();

    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        wizardPageData,
        isPolicyChange,
        policyChangeOverrideProps,
        savePolicyDetailsData,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        policyDetailsService = IMPolicyDetailsService,
        isReadOnly = false
    } = props;

    const {
        updateWizardIssues,
    } = wizardErrorContext;

    const {
        jobID: quoteID, 
        sessionUUID,
        policyInfoSource_Ext: policyInfoSource,
        baseData: {
            primaryNamedInsured_Ext: primaryNamedInsured,
            quoteFlow_Ext: quoteFlow,
            accountHolder: {
                feinOfficialId_Ext: fein,
                organizationTypes_Ext: organizationTypes
            },
            baseState_Ext: baseState,
            productCode
        } = {}
    } = submissionVM.value;

    const lobName = getLobName(productCode)

    const pnidbalist = _.get(submissionVM, 'baseData.pnidbalist_Ext');
    // const history = useHistory();
    const translator = useTranslator();
    // const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const { domainCompany } = useDependencies('domainCompany');
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    // const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

    const policyDetailPath = `lobData.${lobName}.policyDetails`;
    const {
        businessData_Ext: {
            systemDate
        }
    } = authUserData
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('IMPolicyDetailsPage');
    const { getAvailableEffectiveDate } = useProductsData();

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [showPNIInfoMessage, updateShowPNIInfoMessage] = useState(false);
    const [isFEINDisable, updateIsFEINDisable] = useState(false);
    const [isSSNDisable, updateIsSSNDisable] = useState(false);
    const policyDetailsData = _.get(submissionVM, `value.${policyDetailPath}`) || {};
    const {
        ssn,
        // organizationType: organizationTypes
    } = policyDetailsData;

    const getAvailableValues = (path) => {
        const options = _.get(submissionVM.baseData.accountHolder,  `${path}.aspects.availableValues`) || []
        return options.map((item) => {
            return {
                code: item.code,
                name: translator({ id: item.name })
            }
        })
    };
    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
            //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            // }
            if (path === 'baseData.effectiveDate_Ext') {
                _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            }
            const accountHolderPublicID = _.get(submissionVM.value, 'baseData.accountHolder.publicID')
            const pniContactPublicID = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.publicID')
            if(path === 'baseData.accountHolder.feinOfficialId_Ext' && accountHolderPublicID === pniContactPublicID){
                _.set(newSubmissionVM.value, 'baseData.primaryNamedInsured_Ext.feinOfficialId_Ext', value)
            }
            if (path === 'baseData.accountHolder.businessDescription_Ext') {
                _.set(newSubmissionVM.value, 'baseData.primaryNamedInsured_Ext.businessDescription_Ext', value)
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    const removeSSN = () => {
        const newSubmissionVM = _.clone(submissionVM); 
        delete _.get(newSubmissionVM.value, `lobData.${lobName}.policyDetails.ssn`)
        updateSubmissionVM(newSubmissionVM);
    }

        
    const getSSNVisible = useCallback(() => {
        return _.includes(organizationTypes, 'individual_ext')
            || _.includes(organizationTypes, 'soleproprietorship_ext');

    }, [organizationTypes]);

    useEffect(() => {
        if (isPolicyChange) {
            updateWizardIssues([{
                type: 'info',
                reason: translator(BRCLMessages.BR_IM_PolicyChange_Details),
            }]);

            policyChangeOverrideProps.pageOnInit();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getSSNVisible()) {
            if (fein) {
                updateIsSSNDisable(true);
            } else {
                updateIsSSNDisable(false);
            }
            if (ssn) {
                updateIsFEINDisable(true);
            } else {
                updateIsFEINDisable(false);
            }
        } else {
            updateIsSSNDisable(false);
            updateIsFEINDisable(false);
            removeSSN();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationTypes, fein, ssn]);

    const getMinDate = useCallback(() => {
        const availableEffectiveDate = getAvailableEffectiveDate(productCode, baseState);
        // for transactions once rated, enable 5 days behind the current date
        if (quoteFlow !== 'draft' && policyInfoSource !== 'Converted') {
            const before5days = moment(systemDate).add(-5, 'd').startOf('day').toDate()
            return moment(availableEffectiveDate).isBefore(before5days) ? WniDateUtil.getDateObj(before5days): availableEffectiveDate
        }
        return availableEffectiveDate
       
    }, [quoteFlow, systemDate, baseState]);

    const getEffectiveMinDate = useCallback(() => {
        return isPolicyChange ? policyChangeOverrideProps.effectiveMinDate() : getMinDate();
    }, [getMinDate, isPolicyChange, policyChangeOverrideProps])

    const getEffectiveMaxDate = useCallback(() => {
        return isPolicyChange ? policyChangeOverrideProps.effectiveMaxDate() : undefined;
    }, [isPolicyChange, policyChangeOverrideProps])

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM,
                viewModelService,
                showEmployeeFlagToggle: false,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                policyDetailsService: IMPolicyDetailsService,
                policyDetailPath,
                DBAsVM: pnidbalist,
                isReadOnly,
                accountContacts:  _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
                onValueChange: writeValue,
                size: 'lg'
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader, sessionUUID, quoteID, policyDetailPath, pnidbalist, submissionVM, writeValue, modalApi]
    );

    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        _.set(primaryNamedInsuredVM.value, 'isNewAccount_Ext', true)
        // _.set(primaryNamedInsuredVM.value, 'accountOrgType_Ext', 'commonownership')
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.value);

                const newAddress = _.get(updatedVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.value);
                if (accountHolder.publicID === _.get(updatedVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.value);
                }
                const newSubmissionVM = _.clone(submissionVM);
                updateSubmissionVM(newSubmissionVM);
            }).catch(() => _.noop());
    },[showPrimaryNamedInsuredModal, submissionVM, updateSubmissionVM]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {      
        const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.contactName');
        // const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        // const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${displayName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(_.isNil(primaryNamedInsured)) {          
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode')
            if(_.isNil(primaryAddress)){
                primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            }
            // primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress= _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.recommendCode')
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [getPrimaryNamedInsuredDisplayName, primaryNamedInsured, submissionVM.value]);

    const isEffectiveDateValid = useCallback(() => {
        const baseEffectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = getMinDate();
        const minDif = moment(baseEffectiveDate).valueOf() - moment(minDate).valueOf();

        return (minDif >= 0) 
    }, [submissionVM.value, getMinDate]);

    const isTransactionEffectiveDateValid = useCallback(() => {
        return isPolicyChange ? policyChangeOverrideProps.isEffectiveDateValid() : isEffectiveDateValid();
    }, [isPolicyChange, policyChangeOverrideProps, isEffectiveDateValid]);

    const isFeinValid = useCallback(() => {
        if (!fein) {
            return true
        }
        return fein.indexOf('#') === -1 ;
    }, [fein])

    const IMPolicyDetailsValidation = useCallback(() => {
        return isTransactionEffectiveDateValid()
            && WniPNIUtil.isPNIValid(primaryNamedInsured)
            && isFeinValid()
            // && isAgencyAndAgentInfoValid()
    }, [isTransactionEffectiveDateValid, isFeinValid, primaryNamedInsured]);

    useEffect(() => {
        registerComponentValidation(IMPolicyDetailsValidation);
    }, [registerComponentValidation, IMPolicyDetailsValidation, primaryNamedInsured]);


    const handleValidation = useCallback(
        () => {
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },[updateShowErrors, invalidFields]
    );

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const imPolicyDetailsData = _.get(submissionVM.value, `lobData.${lobName}.policyDetails`)
        // const questionSetData = _.get(submissionVM.value, 'lobData.workersComp.preQualQuestionSets')
        const quoteBaseData = _.get(submissionVM.value, 'baseData')
        
        const res = await savePolicyDetailsData(
            quoteID, 
            sessionUUID,
            imPolicyDetailsData,
            // questionSetData,
            quoteBaseData,
            authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        updateSubmissionVM(submissionVM);
        
        // Next step: move this and the service call into HOWizardPage as a common feature
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setLoadingMask, submissionVM, savePolicyDetailsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                showRequired: true,
                readOnly: isReadOnly
            },
            effectiveDate: {
                minDate: getEffectiveMinDate(),
                maxDate: getEffectiveMaxDate(),
                showErrors
            },
            agencyAndProducerInfo: {
                model: submissionVM.baseData,
                onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: false,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: true,
                    licensedAgentReadOnly: isReadOnly,
                    servicingAgentReadOnly: isReadOnly
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate
            },
            primaryNamedInsured: {
                readOnly:true,
                value: getPrimaryNamedInsuredAndDisplay(),
                containerClassName: (!WniPNIUtil.isPNIValid(primaryNamedInsured) && showErrors)? 'validationError' : '',
            },
            primaryNamedInsuredEditIcon: {
                onClick: !showPNIInfoMessage ? () => updateShowPNIInfoMessage(true) : openEditPrimaryNamedInsuredPopup,
                visible: !isReadOnly
            },
            organizationTypeMultiSelect: {
                availableValues: getAvailableValues('organizationTypesOption_Ext')
            },
            IMLineWarningMsg: {
                visible: productCode === IM_PRODUCT_CODE && _.get(domainCompany, 'code') !== 'UIC'
            },
            primaryNamedInsuredNotificationInfo: {
                visible: showPNIInfoMessage,
                // messageProps: { "info": "Updating primary insured information will sync to the account and other quotes "}
            },
            additionalInsuredSection: {
                model: _.get(submissionVM, `${policyDetailPath}.additionalInsureds`) || {},
                onValueChange: writeValue,
                viewModelService,
                industryCode: {
                    code: _.get(primaryNamedInsured, 'industryCode_Ext'),
                    classification: _.get(primaryNamedInsured, 'industryDescription_Ext')
                },
                policyDetailsService:IMPolicyDetailsService,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                organizationTypesOptions: getAvailableValues('organizationTypesOption_Ext'),
                organizationTypes,
                policyDetailPath,
                accountContacts: _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
                policyAddress: _.get(submissionVM, 'value.baseData.policyAddress'),
                existingAddlNamedInsureds: _.get(submissionVM, `value.${policyDetailPath}.unassignedAdditionalNamedInsureds`),
                FEIN: _.get(submissionVM, 'baseData.accountHolder.feinOfficialId_Ext'),
                isReadOnly
            },
            FEIN: {
                disabled: isFEINDisable
            },
            SSN: {
                disabled: isSSNDisable,
                visible: getSSNVisible()
            },
            policyDBA: {
                dbas: _.get(submissionVM, `value.${policyDetailPath}.policyDBAList`),
                isReadOnly,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                onValueChange: writeValue,
                policyDetailPath,
                titleMessage: 'Additional Policy DBAs'
            },
            termType: {
                readOnly:true
            },
            policyState: {
                readOnly:true
            },
            NAICS: {
                readOnly:true
            },
            NAICSDescription: {
                readOnly:true
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getMinDate, getPrimaryNamedInsuredAndDisplay, handleAgencyChange, onValidate, openEditPrimaryNamedInsuredPopup, showErrors, showPNIInfoMessage, submissionVM.baseData]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            anicomponent: ANIComponent,
            policydbacomponent: PolicyDBAComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            showRequiredInfoForFasterQuote
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

IMPolicyDetailsPage.propTypes = {
    ...WizardPage.propTypes,
    policyDetailsService: PropTypes.object,
    savePolicyDetailsData: PropTypes.func,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
};

IMPolicyDetailsPage.defaultProps = {
    ...WizardPage.defaultProps,
    policyDetailsService: IMPolicyDetailsService,
    savePolicyDetailsData: IMPolicyDetailsService.savePolicyDetailsData,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    },
    isPolicyChange: false,
    policyChangeOverrideProps: {}
} 
export default IMPolicyDetailsPage;