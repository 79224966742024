/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon } from '@jutro/components';
import { WindowUtil, ErrorsAndWarningsUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';

import { IconButton } from '@jutro/legacy/components';

import { Link } from '@jutro/router';

const ISSUE_TYPE_TITLE_MAP = {
    warning: 'Warning',
    error: 'Error',
    agentWarning: 'AgentWarning'
};

const ISSUE_TYPE_DISPLAY_ORDER = {
    requiredInfo: 0,
    error: 10,
    agentWarning: 15,
    warning: 20,
    info: 30,
    fine: 40,
};

const ISSUES_CONTAINER_STYLE_MAP = {
    // ref: wni-common-styles/common/_layout.scss
    fine: 'gw-issue-fine',
    info: 'gw-issue-info',
    requiredInfo: 'gw-issue-info',
    warning: 'gw-issue-warning',
    agentWarning: 'gw-issue-warning',
    error: 'gw-issue-error',
    // requiredForIssuanceerror: 'gw-issue-error',
    // requiredForIssuancewarning: 'gw-issue-warning'
};

const ISSUE_ICON_MAP = {
    fine: 'gw-info',
    info: 'gw-info',
    requiredInfo: 'gw-info',
    warning: 'gw-warning',
    agentWarning: 'gw-issue-warning',
    error: 'gw-error',
    // requiredForIssuanceerror: 'fas fa-exclamation-circle',
    // requiredForIssuancewarning: 'fas fa-exclamation-triangle'
};

function getIssueContainerClassName(type, specificBgClass) {
    const retval = _.get(ISSUES_CONTAINER_STYLE_MAP, type, 'gw-issue-error');
    return `${retval} ${specificBgClass}`;
}

function getIssueIconClassName(type, iconClassName) {
    if (iconClassName) {
        return iconClassName;
    }
    const retval = _.get(ISSUE_ICON_MAP, type, 'error');
    return retval;
}

function getIssueSortOrderByType(issueType) {
    return _.get(ISSUE_TYPE_DISPLAY_ORDER, issueType, 0);
}

/**
 * Convert issueType to a displayable title, e.g. 'warning' to 'Warning',
 * 'error' to 'Error'.
 * @param {String} issueType
 * @returns {String}
 */
function getIssueTypeHeader(issueType) {
    const issueTitle = _.get(ISSUE_TYPE_TITLE_MAP, issueType, issueType);
    return issueTitle;
}


function defaultTypeTitleFormatter(type, issues) {
    if (type === 'info') {
        return '';
    }
    const pluralSuffix = issues.length > 1 ? 's' : '';
    const issueTitle = getIssueTypeHeader(type);
    // const retval = `${issues.length} ${type}${pluralSuffix}`;
    const retval = `${issues.length} ${issueTitle}${pluralSuffix}`;
    return retval;
}

function defaultIssueDistinguisher(issue1, issue2) {
    const retval = issue1.reason === issue2.reason && issue1.type === issue2.type;
    return retval;
}

function defaultIssueRenderFn(issue,
    {
        issueKey, listKey, issueJumpFnMap = {},
    }) {
    const { reason } = issue;
    let listContent = reason;
    if (issueKey && issueJumpFnMap[issueKey]) {
        const pageJumpFunc = issueJumpFnMap[issueKey];
        listContent = (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link key={`${listKey}-Link`} onClick={pageJumpFunc}>
                {reason}
            </Link>
        );
    }
    // return <li key={listKey}>{listContent}</li>;
    return listContent;
}

/**
 * Derive list key from current issue
 * @param {object} issue { type: string, reason: string}
 * @returns {string}
 */
const defaultGetListKeyFn = (issue) => {
    const { reason } = issue;
    // Remove Math.random() from listKey when type-reason is enough
    const listKey = `issue-${Math.random()}-${reason}`;
    return listKey;
};
// =======================================================

/*
 * Show content from a list of ValidationIssueDTO
 */
function ValidationIssuesComponent(props) {
    const {
        issuesContainerId: validationIssuesContainerId,
        validationIssues,
        issueJumpFnMap = {},
        icon,
        issueRenderFn,
        typeTitleFormatter,
        typeFooterFormatter,
        issueDistinguisher,
        initiallyCollapsed,
        scrollToIssues,
        getSortKeyForIssueWithSameType,
        specificBgClass,
        issueJumpToFieldFn,
        className
    } = props;
    const translator = useTranslator();
    const [issueTypeHiddenStatus, updateIssueTypeHiddenStatus] = useState({});
    const [issuesSnapshot, updateIssuesSnapshot] = useState([]);

    useEffect(() => {
        //
        if (initiallyCollapsed) {
            const newHiddenStatus = {};
            const issueTypes = _.uniq(validationIssues.map((issue) => issue.type));
            issueTypes.forEach((type) => _.set(newHiddenStatus, type, true));
            updateIssueTypeHiddenStatus(newHiddenStatus);
        }
    }, []);

    useEffect(() => {
        const changes = _.differenceBy(validationIssues, issuesSnapshot, 'reason');
        if (validationIssues && validationIssues.length > 0 && changes && changes.length > 0) {
            updateIssuesSnapshot(validationIssues);
            if (scrollToIssues) {
                WindowUtil.scrollTo(validationIssuesContainerId);
            }
        }
    }, [validationIssuesContainerId, validationIssues, issuesSnapshot]);

    const handleFoldFn = (type) => {
        const newStatus = _.clone(issueTypeHiddenStatus);
        newStatus[type] = !issueTypeHiddenStatus[type];
        updateIssueTypeHiddenStatus(newStatus);
    };

    const getIssueTypeTitle = (type, issues) => {
        if (issues.length <= 1 && !typeTitleFormatter) {
            return null;
        }
        let typeTitle = '';
        if (!typeTitleFormatter) {
            typeTitle = defaultTypeTitleFormatter(type, issues);
        } else if (_.isFunction(typeTitleFormatter)) {
            typeTitle = typeTitleFormatter(type, issues);
            // Consider to enable it if you want to ignore the translator() in formatter func
            // if (typeTitle.id) {
            //     typeTitle = translator(typeTitleFormatter);
            // }
        } else {
            typeTitle = typeTitleFormatter.id ? translator(typeTitleFormatter) : typeTitleFormatter;
        }
        return (
            <div className="message-info-total titleCase">
                {typeTitle}
            </div>
        );
    };

    const getIssueTypeFooter = (type, issues) => {
        if (typeFooterFormatter && _.isFunction(typeFooterFormatter)) {
            // return typeFooterFormatter(type, issues);
            return (
                <div>
                    {typeFooterFormatter(type, issues)}
                </div>
            );
        }
        return null;
    };

    const getIssueDisplayEntry = (issue) => {
        // const { reason, type } = issue;
        const issueKey = ErrorsAndWarningsUtil.getValidationIssueKey(issue);
        const listKey = defaultGetListKeyFn(issue);
        const listContent = issueRenderFn(issue, {
            issueKey,
            listKey,
            issueJumpFnMap,
            defaultIssueRenderFn,
            getIssueKeyFn: ErrorsAndWarningsUtil.getValidationIssueKey,
            getListKeyFn: defaultGetListKeyFn,
            getSortKeyForIssueWithSameType,
        });
        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
                key={listKey}
                onClick={() => issueJumpToFieldFn(issue)}
            >
                {listContent}
            </li>
        );
    };

    const renderValidationDom = (type, unsortedIssues) => {
        if (unsortedIssues.length === 0) {
            return null;
        }
        const classNames = getIssueContainerClassName(type, specificBgClass);
        const iconClassNames = getIssueIconClassName(type, icon);
        const isOpen = !issueTypeHiddenStatus[type];
        const issueContainerHideStyle = unsortedIssues.length > 1 && !isOpen ? 'hide' : '';

        let issues = unsortedIssues;
        if (getSortKeyForIssueWithSameType) {
            issues = _.sortBy(issues, getSortKeyForIssueWithSameType);
        }

        const issuesContainerClass = issues.length > 1 ? '' : 'withoutListStyle'
        return (
            <div key={type} className="notification-message-wrapper notification-container">
                <div className={classNames}>
                    <div className={`message-icon ${icon && 'md'}`}>
                        <Icon icon={iconClassNames} />
                    </div>
                    <div className="message-info">
                        {getIssueTypeTitle(type, issues)}
                        <ul className={`${issueContainerHideStyle} ${issuesContainerClass}`}>
                            {
                                issues.map(getIssueDisplayEntry)
                            }
                        </ul>
                        {getIssueTypeFooter(type, issues)}
                    </div>
                    {
                        issues.length > 1 && (
                            <IconButton
                                icon={isOpen ? 'gw-keyboard-arrow-down' : 'gw-keyboard-arrow-right'}
                                className="message-collapse"
                                iconColor="dark"
                                id="IconButton"
                                size="medium"
                                onClick={() => handleFoldFn(type)}
                            />
                        )
                    }
                </div>
            </div>
        );
    };
    const renderIssues = (issues) => {
        let issuesToDisplay = issues;
        if (issueDistinguisher) {
            const uniqFn = _.isFunction(issueDistinguisher) ? issueDistinguisher
                : defaultIssueDistinguisher;
            issuesToDisplay = _.uniqWith(issues, uniqFn);
        }

        const groupedIssues = _.groupBy(issuesToDisplay, (issue) => issue.type);
        const typeTopIssuesArray = _.toPairs(groupedIssues);
        const sortedIssuesArray = _.sortBy(typeTopIssuesArray,
            (entry) => getIssueSortOrderByType(entry[0]));

        return (
            <>
                {sortedIssuesArray.map((entry) => renderValidationDom(entry[0], entry[1]))}
            </>
        );
    };

    if (_.isEmpty(validationIssues)) {
        return (null);
    }

    return (
        <div id={validationIssuesContainerId} className={className}>
            {renderIssues(validationIssues)}
        </div>
    );
}


ValidationIssuesComponent.propTypes = {
    /**
     * (Required) Issues to display in this component, with form of
     * [{ type, reason, flowStepId (Optional)}]
     */
    validationIssues: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        reason: PropTypes.string,
    })).isRequired,
    /**
     * (Optional) A map with form of { flowStepId: jumpToPageFn()}.
     * If this map is not empty, then then ValidationIssuesComponent will
     * render a link based on issue.flowStepId. When this link is clicked,
     * the jumpToPageFn will be invoked.
     */
    issueJumpFnMap: PropTypes.shape({}),
    /**
     * (Optional) The validation issues container id, helps to locate the whole element
     */
    issuesContainerId: PropTypes.string,
    /**
     * (Optional) Font Awesome icon class name, e.g. 'fas fa-exclamation-circle'.
     * See https://fontawesome.com/v4.7.0/icons for reference
     */
    icon: PropTypes.string,
    /**
     * (Optional) Title shown on the expandable panel. Default value will be generated
     * from issue.type, e.g. "warning", "warnings", "error", "errors", etc.
     *
     * @Deprecated replaced by typeTitleFormatter
     */
    // messageTip: PropTypes.object || PropTypes.string,
    /**
     * (Optional) A render function -- if present -- will take care of the issue rendering.
     * Signature: (issue, {
     *  issueKey, listKey, issueJumpFnMap, defaultIssueRenderFn,
     *  getIssueKeyFn, getListKeyFn, getSortKeyForIssueWithSameType
     * }) => ReactComponent
     */
    issueRenderFn: PropTypes.func,
    issueJumpToFieldFn: PropTypes.func,
    // /**
    //  * (Optional) An Id generator that provides ID for issue container of a specific type
    //  */
    // issueContainerIdGeter: PropTypes.func,
    /**
     * (Optional) the title formatter for specific issue type, which returns
     * something like "info(s)", "warning(s)", etc.
     * Signature: (type, issues) => string
     */
    typeTitleFormatter: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.shape({})]),
    /**
     * (Optional) the issue type footer render func
     * Signature: (type, issues) => footer component
     */
    typeFooterFormatter: PropTypes.func,
    /**
     * (Optional) Whether uniq all issues by type and reason, or based on the
     * function passed here.
     * Signature: (issue1, issue2) => boolean, used as second parameter of _.uniqWith()
     * default: false
     */
    issueDistinguisher: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    /**
     * (Optional) Describes whether the issues panel should be in Collapsed status in
     * the beginning
     */
    initiallyCollapsed: PropTypes.bool,
    /**
     * (Optional): Whether scroll to validation issues.
     */
    scrollToIssues: PropTypes.bool,
    /**
     * (Optional): this sort func will be fed into _.sortBy() for issue sorting.
     *
     * Function signature: (ValidationIssueDTo) => String
     */
    getSortKeyForIssueWithSameType: PropTypes.func,
    specificBgClass: PropTypes.string,
    className: PropTypes.string
};

ValidationIssuesComponent.defaultProps = {
    // A list of edge.capabilities.policycommon.validation.dto.ValidationIssueDTO
    // validationIssues: [],
    issueJumpFnMap: undefined,
    issuesContainerId: 'validationIssuesComponentId',
    icon: undefined,
    // messageTip: undefined,
    issueRenderFn: defaultIssueRenderFn,
    issueJumpToFieldFn: _.noop,
    // issueContainerIdGeter: undefined,
    typeTitleFormatter: null,
    typeFooterFormatter: undefined,
    issueDistinguisher: false,
    initiallyCollapsed: false,
    specificBgClass: '',
    scrollToIssues: false,
    getSortKeyForIssueWithSameType: undefined,
    className: ''
};

export default ValidationIssuesComponent;
