import React, {
    useContext,
    useCallback,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DropdownMenuButton } from "@jutro/components";
import { DropdownMenuLink } from '@jutro/router';
import { FieldLinkComponent } from 'gw-components-platform-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { ServiceManager } from '@jutro/legacy/services';
import {
    WindowUtil,
    ConfigUtil,
    ValidationIssueUtil,
    QuoteUtil,
    WniProductsUtil,
} from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
// import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { IMBuildingsAndLocationsService } from 'wni-capability-quoteandbind-im';
import WizardPage from '../../templates/IMWizardPage';
import metadata from './IMBuildingsAndLocationsPage.metadata.json5';
import messages from './IMBuildingsAndLocationsPage.messages';
import IMLocationDetailModal from './Components/IMLocationDetailModal/IMLocationDetailModal';
import IMBuildingDetailModal from './Components/IMBuildingDetailModal/IMBuildingDetailModal';

const { 
    CPP_PRODUCT_CODE
} = WniProductsUtil;

const COVERABLES_PATH = 'lobData.inlandMarine.coverables';
const unusedLocationsVMPath = `${COVERABLES_PATH}.unusedLocations`;
const unusedBuildingsVMPath = `${COVERABLES_PATH}.unusedBuildings`;
const locationsVMPath = `${COVERABLES_PATH}.locations`;
const CPP_LOCATIONS_PATH = 'lobData.commercialPackage.coverables.locations';

const OPERATIONTYPE = {
    edit: 'Edit',
    new: 'New',
    readOnly: 'View'
}


function IMBuildingsAndLocationsPage(props) {
    const modalApi = useWniModal();

    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        isReadOnly,
    } = props;

    const {
        jobID, sessionUUID,
        baseData: {
            productCode
        } = {},
        lobData: {
            inlandMarine: {
                coverables: {
                    unusedLocations = [],
                    unusedBuildings = [],
                    locations = []
                }
            }
        }
    } = submissionVM.value;

    const translator = useTranslator();
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    
    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('IMBuildingsAndLocationsPage');
    const [showErrors, updateShowErrors] = useState(false);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [selection, updateSelection] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [invalidFields]);

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
    
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const updateSubmissionVMForResponse = useCallback((res) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${locationsVMPath}.value`, res.locations);
        _.set(newSubmissionVM, `${unusedLocationsVMPath}.value`, res.unusedLocations);
        _.set(newSubmissionVM, `${unusedBuildingsVMPath}.value`, res.unusedBuildings);
        if(productCode === CPP_PRODUCT_CODE && res.cppLocations) {
            _.set(newSubmissionVM, CPP_LOCATIONS_PATH, res.cppLocations)
        }
        updateWizardSnapshot(newSubmissionVM);
        return newSubmissionVM;
    },[submissionVM, updateWizardSnapshot, viewModelService, productCode]);


    const locationService = async (serviceName, serviceData) => {
        setLoadingMask(true);
        const res = await IMBuildingsAndLocationsService[serviceName](jobID, sessionUUID, serviceData, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        updateSelection([]);
        updateShowErrors(false);
        generateValidationIssues(res.errorsAndWarnings);
        setLoadingMask(false);
        return newSubmissionVM;
    }

    const showLocationDetailModal = async (locationVM, isEdit) => {
        let operationType = isEdit ? OPERATIONTYPE.edit : OPERATIONTYPE.new ;
        if(isReadOnly) {
            operationType = OPERATIONTYPE.readOnly
        }
        const componentProps = {
            title: translator(messages.locationDetailModalTitle, {operationType}),
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            locationVM,
            viewModelService,
            IMBuildingsAndLocationsService,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            isReadOnly
        }
        await modalApi.showModal(<IMLocationDetailModal {...componentProps} />).then(async(result) => {
            if(!result) {
                return false;
            }
            updateSubmissionVMForResponse(result);
        }).catch(() => _.noop());
    }

    const showBuildingDetailModal = async (buildingVM, isEdit) => {
        let operationType = isEdit ? OPERATIONTYPE.edit : OPERATIONTYPE.new ;
        if(isReadOnly) {
            operationType = OPERATIONTYPE.readOnly
        }
        const componentProps = {
            title: translator(messages.buildingDetailModalTitle, {operationType}),
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            buildingVM,
            viewModelService,
            IMBuildingsAndLocationsService,
            isReadOnly
        }
        await modalApi.showModal(<IMBuildingDetailModal {...componentProps} />).then(async(result) => {
            if(!result) {
                return false;
            }
            updateSubmissionVMForResponse(result);
        }).catch(() => _.noop());
    }

    const getLink = (item, index, { id: property, path}) => {
        let fieldProps = {};
        const locationsVM = _.get(submissionVM, `${locationsVMPath}.children`,[]);
        if(item.isLocation) {
            const currentLocationVM = locationsVM.find(vm => vm.value.rowIdPath === index );
            fieldProps = {
                onClick: () => showLocationDetailModal(currentLocationVM, true)
            }
        } else {
            const buildingCurrentLocationVM = locationsVM.find(vm => vm.value.rowIdPath === item.locationPublicID );
            const buildingsVM = _.get(buildingCurrentLocationVM, 'buildings.children', []);
            const currentBuildingVM = buildingsVM.find(vm => vm.value.rowIdPath === index );
            fieldProps = {
                onClick: () => showBuildingDetailModal(currentBuildingVM, true)
            }
        }

        return (
            <FieldLinkComponent
                className={item.isLocation ? "font-bold" : 'indent'}
                id={`${property}${item[property]}`}
                value={item[path]}
                {...fieldProps}
            />
        );
    }

    const renderAllUnusedLocationsMenuItem = () => {
        const retval = _.filter(unusedLocations, (elt) => {
            return !_.isUndefined(elt.address.addressLine1);
        }).map((item) => {
            const address = _.get(item, 'address');
            const { publicID, displayName } = address;

            const locationsVM = _.get(submissionVM, `${locationsVMPath}`);
            const { _dtoName, _xCenter } = locationsVM;
            const newLocationVM = viewModelService.create(
                item,
                _xCenter,
                _dtoName
            );
            return (
                <DropdownMenuLink
                    type="action"
                    onClick={() => showLocationDetailModal(newLocationVM, true)}
                    key={`${publicID}-MenuLink`}
                >
                    {displayName}
                </DropdownMenuLink>
            );
        });
        return retval;
    }

    const renderAllUnusedBuildingsMenuItem = () => {
        const retval = unusedBuildings.map((item) => {
            const { publicID, displayName, locationPublicID } = item;
            const createBuildingVM = viewModelService.create(
                {
                    locationPublicID,
                    buildingPublicID_Ext: publicID
                },
                'pc',
                'wni.edge.capabilities.quote.lob.inlandmarine.dto.IMBuildingDTO'
            );
            return (
                <DropdownMenuLink
                    type="action"
                    onClick={() => showBuildingDetailModal(createBuildingVM, true)}
                    key={`${publicID}-MenuLink`}
                >
                    {displayName}
                </DropdownMenuLink>
            );
        });
        return retval;
    }

    const addNewLocation = () => {
        const locationsVM = _.get(submissionVM, `${locationsVMPath}`);
        const { _dtoName, _xCenter } = locationsVM;

        const newLocationVM = viewModelService.create(
            { isNonSpecific: false, rowIdPath: ConfigUtil.getUuid(),  address: {country: defaultCountryCode} },
            _xCenter,
            _dtoName
        );
        showLocationDetailModal(newLocationVM);
    }

    const addNewBuilding = (item) => {
        const createBuildingVM = viewModelService.create(
            {
                rowIdPath: ConfigUtil.getUuid(), 
                locationDisplayName: item.displayName,
                locationPublicID: item.publicID,
                buildingNum: item.buildingNumber + 1
            },
            'pc',
            'wni.edge.capabilities.quote.lob.inlandmarine.dto.IMBuildingDTO'
        );
        showBuildingDetailModal(createBuildingVM, false);
    }

    const removeLocation = (item) => {
        modalApi.showConfirm({
            title: messages.removeLocationTitle,
            message: messages.removeLocationDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel,
        }).then(async (result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            await locationService('removeLocation', item.publicID);
        });
    }

    const removeBuildings = () => {
        modalApi.showConfirm({
            title: messages.removeBuildingsTitle,
            message: messages.removeBuildingsDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel,
        }).then(async (result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            await locationService('removeBuildings', selection);
        });
    }

    const renderActionCell = (item) => {
        if(!item.isLocation) {
            return null;
        }
        const dom = (
            <DropdownMenuButton
                icon="gw-expand-more"
                id="dropdownMenuButton"
                className="dropDownMenuIconbtn"
                menuClassName="dropDownMenuList"
            >
                <DropdownMenuLink onClick={() => addNewBuilding(item)}>Add Building</DropdownMenuLink>
                <DropdownMenuLink onClick={() => removeLocation(item)}>Remove Location</DropdownMenuLink>
            </DropdownMenuButton>
        );
        return WniTableRowUtil.renderCell(item.rowIdPath, dom);
    };

    const getLocationTableData = useCallback(() => {
        const res = _.flatMap(locations, item => {
            const locationArray = {
                ..._.omit(item, 'buildings'),
                isLocation: true,
                unselectable: true,
                buildingNumber: item.buildings.length
            }
            return [
                locationArray,
                ...item.buildings
            ]
        });
        return res;
    }, [locations]);

    const onPageNext = useCallback(async () => {
        const requestData = {
            jobID,
            sessionUUID,
        }
        const res = await IMBuildingsAndLocationsService.onPageNext(requestData, authHeader);
        const newSubmissionVM = updateSubmissionVMForResponse(res);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return newSubmissionVM;
    }, [authHeader, submissionVM]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
                readOnly: isReadOnly
            },
            locationTable: {
                data: getLocationTableData(),
                onSelectionChange: (rows) => updateSelection(rows)
            },
            addLocation: {
                visible: !isReadOnly
            },
            action: {
                visible: !isReadOnly
            },
            removeBuilding: {
                visible: !isReadOnly,
                disabled: selection.length === 0,
            },
            addExistingLocation: {
                visible: !isReadOnly,
                disabled: unusedLocations.length === 0,
                content: renderAllUnusedLocationsMenuItem()
            },
            addExistingBuilding: {
                visible: !isReadOnly,
                disabled: unusedBuildings.length === 0,
                content: renderAllUnusedBuildingsMenuItem()
            }
        };
    }, [isReadOnly, getLocationTableData, selection, unusedLocations, unusedBuildings]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
            addNewLocation,
            showBuildingDetailModal,
            getLink,
            renderActionCell,
            removeBuildings
        },
        resolveComponentMap: {
        }
    };
    
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            showRequiredInfoForFasterQuote
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

IMBuildingsAndLocationsPage.propTypes = WizardPage.propTypes;
IMBuildingsAndLocationsPage.defaultProps = WizardPage.defaultProps;
export default IMBuildingsAndLocationsPage;