import React, {
    useCallback, useContext, useRef, useState, useEffect
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// import { ComponentRenderHelper as renderHelper } from 'wni-portals-util-react';
import metadata from './WniFileUpload.metadata.json5';


function WniFileUpload(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        className,
        labelPosition,
        type,
        data = []
    } = props;

    const componentProps = _.omit(props, ['showTable', 'data']);

    const renderSize = (item, index, { path }) => {
        const num = 1000;
        return `${(item[path]/num).toFixed(2)}KB`;
    }
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        fileUploader: {
            ...componentProps,
            className: `wniFileUpload ${type} ${className}`
        },
        fileUploadTableContainer: {
            className: `fileUploadTable ${labelPosition}`
        },
        fileUploadTable: {
            visible: !_.isEmpty(data),
            data: data
        }
    };
    

    const resolvers = {
        resolveCallbackMap: {
            renderSize
        }
    };
    return (
        <ViewModelForm
            model={{}}
            overrideProps={overrideProps}
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

export default WniFileUpload;
