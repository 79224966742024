import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { CheckboxElement } from 'wnice-components-platform-react';
import { useModal, Button } from '@jutro/components';
import { CheckboxField } from '@jutro/legacy/components';
import { ProductUtil, TableRowUtil } from 'wni-portals-util-react';
import { PAVehicleService } from 'wni-capability-claim-pa';
import { WTWatercraftService } from 'wni-capability-claim-wt';
import { dtoName, xCenter, wtDtoName } from '../../config/vehicles.static';
import VehicleDetails from '../VehicleDetails/VehicleDetails';
import WatercraftDetails from '../VehicleDetails/WatercarftDetails';
import RTVehicleDetails from '../VehicleDetails/RTVehicleDetails';
import ThirdPartyVehicleDetails from '../VehicleDetails/ThirdPartyVehicleDetails';
import metadata from './PolicyVehicle.metadata.json5';
import messages from './PolicyVehicle.messages';
import styles from './PolicyVehicle.module.scss'

const {
    PA_LOB_NAME,
    RT_LOB_NAME,
    WT_LOB_NAME,
    CA_LOB_NAME
} = ProductUtil;

function ButtonWithTips(props) {
    const { buttonText } = props;
    const translator = useTranslator();

    const componentProps = _.omit(props, ['tip', 'buttonText']);
    return (
        <Button {...componentProps} label={translator(buttonText)}/>
    );
}

function PolicyVehicle(props) {
    const modalApi = useModal();
    const {
        claimVM,
        basePath,
        authHeader,
        tableData = [],
        onValidate = _.noop,
        LOB_NAME,
        updateWizardData,
        updateWizardSnapshot,
        isReadOnly = false,
        incidentType,
        propertyOwnerContacts
    } = props;

    const {
        claimNumber,
        lobs: {
            [LOB_NAME]: {
                initalVehicleIncidentData_Ext: initalVehicleIncidentData,
                vehicleIncidents: vehicleIncidentsData = [],
                thirdPartyIncidents_Ext: thirdPartyIncidents
            } = {},
        },
    } = claimVM.value

    const vehicleOwnerContactsIndex = thirdPartyIncidents?.findIndex(elt => !_.isNil(elt.vehicleOwnerContacts_Ext));
    const vehicleOwnerContacts = _.get(thirdPartyIncidents, `${vehicleOwnerContactsIndex}.vehicleOwnerContacts_Ext`);

    const PAGE_TYPE_NAME = LOB_NAME === WT_LOB_NAME ? 'watercraft' : 'vehicle';
    const dtoPath = LOB_NAME === WT_LOB_NAME ? wtDtoName : dtoName;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const VehicleService = LOB_NAME === WT_LOB_NAME ? WTWatercraftService : PAVehicleService;

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const [currentRow, updateCurrentRow] = useState(null);
    const [vehicleTableData, updateVehicleTableData] = useState([]);
    const [searchValue, updateSearchValue] = useState(null);
    const [filterMapData, updateFilterMapData] = useState(null);
    const [availableDrivers, updateAvailableDrivers] = useState(null);
    const [availablePassengers, updateAvailablePassengers] = useState(null);
    const [isEditing, updateIsEditing] = useState(false);
    const [initIncidentData] = useState(_.cloneDeep(initalVehicleIncidentData));
    const [isExistingPolicyVehicle, updateIsExistingPolicyVehicle] = useState(currentRow?.value.isPolicyVehicle)
    const [selectedDriverId, updateSelectedDriverId] = useState()
    const [selectedVehiclePublicIDs, setSelectedVehiclePublicIDs] = useState([])
    const highlightRowFn = useCallback(
        (activeRow) => {
            const activePublicID = activeRow ? _.get(activeRow.value, `${PAGE_TYPE_NAME}.publicID`) : null;
            TableRowUtil.setTablePublicIDSelected(activePublicID, 'vehicleTable');
        },[PAGE_TYPE_NAME]
    )

    const syncWizardData = useCallback(
        (data) => {
            const newClaimVM = viewModelService.clone(claimVM);
            _.set(newClaimVM.value, `lobs.${LOB_NAME}.${basePath}_Ext`, data.vehicles);
            _.set(newClaimVM.value, `lobs.${LOB_NAME}.vehicleIncidents_Ext`, data.vehicleIncidents);
            _.set(newClaimVM.value, `lobs.${LOB_NAME}.initalVehicleIncidentData_Ext`, data.initalVehicleIncidentData);
            _.set(newClaimVM.value, `lobs.${LOB_NAME}.thirdPartyIncidents_Ext`, data.thirdPartyIncidents);
            if (!isReadOnly) {
                updateWizardData(newClaimVM);
            }
        },[LOB_NAME, basePath, claimVM, isReadOnly, updateWizardData, viewModelService]
    ) 

    const syncWizardDataSnapshot = useCallback((response) => {
        const newClaimVM = viewModelService.clone(claimVM);
        _.set(newClaimVM.value, `lobs.${LOB_NAME}.${basePath}_Ext`, response[basePath]);
        _.set(newClaimVM.value, `lobs.${LOB_NAME}.vehicleIncidents_Ext`, response.vehicleIncidents);
        _.set(newClaimVM.value, `lobs.${LOB_NAME}.initalVehicleIncidentData_Ext`, response.initalVehicleIncidentData);
        _.set(newClaimVM.value, `lobs.${LOB_NAME}.thirdPartyIncidents_Ext`, response.thirdPartyIncidents);
        if (!isReadOnly) {
            updateWizardSnapshot(newClaimVM);
        }
    },[LOB_NAME, basePath, claimVM, isReadOnly, updateWizardSnapshot, viewModelService])

    const saveIncident = useCallback(
        async (rowValue) => {
            setLoadingMask(true);
            const res = await VehicleService.saveVehicleIncident(
                claimNumber,
                rowValue,
                authHeader
            );
            setLoadingMask(false);
            syncWizardDataSnapshot(res);
            syncWizardData(res);
        }, [VehicleService, authHeader, claimNumber, setLoadingMask, syncWizardDataSnapshot, syncWizardData]
    )

    const handleContactSelectionChange = useCallback((ids, type, rowData, updateSelection) => {
        const rowPassengerOptions = _.get(rowData, 'value.passengerMap');
        const rowDriverOptions = _.get(rowData, 'value.driverMap');
        const rowDriver = _.get(rowData, 'value.driver');
        const rowPassengers = _.get(rowData, 'value.passengers');
        let allContacts = _.uniqBy(_.concat(rowPassengerOptions, rowDriverOptions, rowDriver, rowPassengers), 'rowIdPath');
        allContacts = _.filter(allContacts, (contact) => !_.isEmpty(contact));
        if (type === 'driver') {
            updateSelection(ids);
            updateSelectedDriverId(ids)
            const newPassengerList = _.filter(allContacts, passenger => !_.includes(ids, passenger.rowIdPath));
            // update the available passengers
            updateAvailablePassengers(newPassengerList);
        } else {
            updateSelection(ids);
            const newDriverList = _.filter(allContacts, driver => !_.includes(ids, driver.rowIdPath));

            // update the available drivers
            updateAvailableDrivers(newDriverList);
        }
    }, []);

    useEffect(() => {
        updateVehicleTableData(_.cloneDeep(tableData));
    }, [tableData]);

    useEffect(() => {
        highlightRowFn(currentRow);
        
        if(currentRow?.value[PAGE_TYPE_NAME]?.isPolicyVehicle){
            updateIsExistingPolicyVehicle(true)
        }else{
            updateIsExistingPolicyVehicle(false)
        }
    }, [LOB_NAME, PAGE_TYPE_NAME, currentRow, highlightRowFn]);

    useEffect(() => {
        const dataInfo = {};
        tableData.forEach((item) => {
            const { make } = item;
            if (!dataInfo[make]) {
                dataInfo[make] = []
            }
            dataInfo[make].push(item);
        });
        updateFilterMapData(dataInfo);
    }, [tableData]);

    const filterBySearchValue = useCallback(
        () => {
        const initTableData = _.cloneDeep(tableData);
        if (!searchValue || searchValue === '') {
            updateVehicleTableData(initTableData);
            return false;
        }
        const searchData = initTableData.filter((item) => item.vin && item.vin.slice(-4) === searchValue);
        updateVehicleTableData(searchData);
    },[searchValue, tableData])

    const handleFilterChange = useCallback(
        (value, key, vehicle) => {
        const newData = _.cloneDeep(filterMapData);
        const currentIndex = newData[key].findIndex((v) => v.publicID === vehicle.publicID);
        _.set(newData[key], currentIndex, {...vehicle, checked: value} );
        updateFilterMapData(newData);
        const checkedVehicles = _.flatten(_.values(newData)).filter((item) => item.checked);
        // const checkedVehiclesData = checkedVehicles.map((item) => {return { make: item.make, model: item.model}});
        // updateFilterValue(checkedVehiclesData);
        if(_.isEmpty(checkedVehicles)) {
            updateVehicleTableData(_.cloneDeep(tableData));
        } else {
            updateVehicleTableData(checkedVehicles);
        }
    },[filterMapData, tableData])

    const renderModelFilter = useCallback(
        () => {
        if(!filterMapData) {
            return null;
        }
        const keysMap = _.keys(filterMapData) || [];
        return _.map(keysMap, (key) => {
            const keyValue = filterMapData[key];
            return (
                <ul key={`make${key}`} className="dropdownMenuItemWrapper">
                    <div className="dropdownMenuItemTitle">{key}</div>
                    {keyValue.map((v, i) => {
                        return (
                            <CheckboxElement
                                className="dropdownMenuItem"
                                key={`model${i}`}
                                label={v.model}
                                value={!!v.checked}
                                onValueChange={(val) => handleFilterChange(val, key, v)}
                            />
                        );
                    })}
                </ul>
            );
        });
    },[filterMapData, handleFilterChange])

    const onRowClick = useCallback(async (item) => {
        if (!item) {
            updateCurrentRow(null);
            updateIsEditing(false);
            return null;
        }
        if (isEditing) {
            return false;
        }
        if(currentRow && _.get(currentRow.value, `${PAGE_TYPE_NAME}.publicID`) === item.publicID) {
            return false
        }
        let res = {}
        setLoadingMask(true);
        if(item.hasIncident) {
            res = await VehicleService.getOrCreateVehicleIncident(
                claimNumber,
                item.incidentPublicId,
                authHeader
            );
        } else {
            res = initIncidentData;
        }
        
        setLoadingMask(false);
        const initRowData = {
            ...res,
            lossPartyType: incidentType,
            [PAGE_TYPE_NAME]: {...item},
            pageTypeName: PAGE_TYPE_NAME,
            isSave: false,
            watercraftType: res.watercraftType || 'listed',
            location: res.location || { country: 'US'}
        };
        const initCurrentRow = viewModelService.create(initRowData, xCenter, dtoPath);

        updateCurrentRow(initCurrentRow);
        updateIsEditing(true);
    },[PAGE_TYPE_NAME, VehicleService, authHeader, claimNumber, currentRow, dtoPath, initIncidentData, isEditing, setLoadingMask, viewModelService, incidentType]);

    const addIncidentVehicle = useCallback(
        async() => {
            const initRowData = {
                ...initIncidentData,
                pageTypeName: PAGE_TYPE_NAME,
                isSave: false,
                isNewCreate: true,
                lossPartyType: incidentType,
                rowIdPath: `cc:${TableRowUtil.getUuid()}`,
                publicID: `temp:${TableRowUtil.getUuid()}`,
                location: { country: 'US'},
                [PAGE_TYPE_NAME]: {
                    watercraft: {},
                    motor: {},
                    trailer: {},
                    publicID: `temp:${TableRowUtil.getUuid()}`,
                },
            };
            
            const rowVM = viewModelService.create(initRowData, xCenter, dtoPath);

            const incidentPath = incidentType === 'insured' ? 'vehicleIncidents_Ext' : 'thirdPartyIncidents_Ext';
            const vehicleIncidents = _.get(claimVM.value, `lobs.${LOB_NAME}.${incidentPath}`);
            const newTableData = [...vehicleIncidents, initRowData];
            const newClaimVM = viewModelService.clone(claimVM);
            _.set(newClaimVM.value, `lobs.${LOB_NAME}.${incidentPath}`, newTableData);

            if (!isReadOnly) {
                updateWizardData(newClaimVM);
            }
            
            updateCurrentRow(rowVM);
            updateIsEditing(true);
        },[LOB_NAME, PAGE_TYPE_NAME, claimVM, dtoPath, initIncidentData, isReadOnly, updateWizardData, viewModelService, incidentType]
    ) 

    const removeVehicleIncident = useCallback(
       async (item) => {
        modalApi.showConfirm({
            title: messages.removeIncidentTitle,
            message: messages.removeIncident,
            status: 'warning',
            icon: 'gw-error-outline',
        }).then(async (results) => {
            if (results === 'cancel' || results === 'close') {
                updateIsEditing(false);
                return false;
            }
            setLoadingMask(true);
            const res = await VehicleService.removeVehicleIncident(
                claimNumber,
                item.incidentPublicId,
                authHeader
            );
            setLoadingMask(false);
            syncWizardDataSnapshot(res);
            return true;
        }, _.noop);
    },[VehicleService, authHeader, claimNumber, modalApi, setLoadingMask, syncWizardDataSnapshot])

    const removeThirdPartyVehicleIncidents = useCallback((incidentPublicIds) => {
         modalApi.showConfirm({
             title: messages.removeIncidentTitle,
             message: messages.removeIncident,
             status: 'warning',
             icon: 'gw-error-outline',
         }).then(async (results) => {
             if (results === 'cancel' || results === 'close') {
                 updateIsEditing(false);
                 return false;
             }
             incidentPublicIds.forEach(async incidentPublicId => {
                setLoadingMask(true);
                const res = await VehicleService.removeVehicleIncident(
                    claimNumber,
                    incidentPublicId,
                    authHeader
                );
                syncWizardDataSnapshot(res);
                setLoadingMask(false);
             })
             return true;
         }, _.noop);
     },[VehicleService, authHeader, claimNumber, modalApi, setLoadingMask, syncWizardDataSnapshot])

    const removeIncidentVehicles = useCallback(() => {
        const incidentPublicIds = vehicleTableData.filter(elt => selectedVehiclePublicIDs.includes(elt.publicID))?.map(data => data.incidentPublicId);
        if (!_.isEmpty(incidentPublicIds)) {
            removeThirdPartyVehicleIncidents(incidentPublicIds);
        }
    }, [removeThirdPartyVehicleIncidents, selectedVehiclePublicIDs, vehicleTableData])

    const handleCheckboxChange = useCallback(
        async(val, item) => {
        if(val) {
            await onRowClick(item);
            return false;
        }
        await removeVehicleIncident(item)
    },[onRowClick, removeVehicleIncident])

    const renderIncidentIcon = useCallback(
        (item) => {
        const dom = <CheckboxField value={item.hasIncident} onValueChange={(val) => handleCheckboxChange(val, item)} />
        return TableRowUtil.renderCell(item.publicID, dom);
    },[handleCheckboxChange])

    const saveAndClose = useCallback(
        async(rowValue) => {
            await saveIncident(rowValue);
            updateCurrentRow(null);
            updateIsEditing(false);
            updateAvailableDrivers(null);
            updateAvailablePassengers(null);
        },[saveIncident, updateAvailableDrivers, updateAvailablePassengers, updateIsEditing]
    ) 

    const handleCancel = useCallback(
        () => {
        // resetWizardDataToSnapshot();
        updateCurrentRow(null);
        updateIsEditing(false);
        updateAvailableDrivers(null);
        updateAvailablePassengers(null);
    },[updateIsEditing]);

    const writeValue = useCallback(
        (value, path) => {
        if(currentRow) {
            const initCurrentRow = viewModelService.clone(currentRow);
            const isCurrencyField = _.isObject(value) && _.get(value, 'currency');
            if (isCurrencyField && (_.isNil(_.get(value, 'amount')) || _.get(value, 'amount') === '')){
                _.set(initCurrentRow.value, path, undefined);
            } else {
                _.set(initCurrentRow.value, path, value);
            }
            updateCurrentRow(initCurrentRow);
            updateIsEditing(true);
        }
    },[currentRow, updateIsEditing, viewModelService])

    const getAddVehicleBtnInfo = useCallback(() => {
        if (incidentType === 'insured') {
            return {
                buttonText: translator(messages.insuredButtonText, {pageTypeName: _.upperFirst(PAGE_TYPE_NAME)}),
                tip: translator(messages.insuredButtonTip, {pageTypeName: _.upperFirst(PAGE_TYPE_NAME), pageType: _.upperFirst(PAGE_TYPE_NAME)})
            }
        }
        return {
            buttonText: translator(messages.thirdButtonText, {pageTypeName: _.upperFirst(PAGE_TYPE_NAME)}),
            // tip: translator(messages.thirdButtonTip, {pageTypeName: _.upperFirst(PAGE_TYPE_NAME)})
        }
    }, [PAGE_TYPE_NAME, incidentType, translator]);

    const renderThirdPartyActionCell = useCallback((item, index) => {
        return (
            <Flex gap="small" justifyContent="right" className="mb-10">
            <Button
                id={`ViewEditBtn_${index}`}
                className="btn-link"
                onClick={() => onRowClick(item)}
                label={translator(messages.viewAndEditLabel)}
                disabled={!!currentRow}
            />
            </Flex>
        )
    }, [currentRow, onRowClick, translator]);

    const generageOverrides = useMemo(() => ({
            '@field': {
                labelPosition: 'left',
            },
            '@element': {
                claimNumber,
                currentRow,
                updateCurrentRow,
                writeValue,
                onValidate,
                authHeader,
                claimVM,
                isPolicyVehicle: isExistingPolicyVehicle,
                cancel: handleCancel,
                save: (rowValue) => saveAndClose(rowValue),
                VehicleService,
                availableDrivers: availableDrivers,
                availablePassengers: availablePassengers,
                handleContactSelectionChange,
                incidentType
            },
            vehicleSearch: {
                value: searchValue,
                onValueChange: (val) => updateSearchValue(val),
                actionFn: filterBySearchValue,
                onEnter: filterBySearchValue
            },
            addVehicleButton: {
                visible: incidentType !== 'third_party',
                disabled: isEditing,
                onClick: addIncidentVehicle,
                buttonText: getAddVehicleBtnInfo().buttonText,
                tip: getAddVehicleBtnInfo().tip
            },
            addThirdPartyVehicleButton: {
                visible: incidentType === 'third_party',
                disabled: isEditing,
                onClick: addIncidentVehicle,
            },
            deleteThirdPartyVehicleButton: {
                visible: incidentType === 'third_party',
                disabled: _.isEmpty(selectedVehiclePublicIDs) || _.isEmpty(vehicleTableData),
                onClick: removeIncidentVehicles
            },
            filterByModel: {
                content: renderModelFilter(),
            },
            vehicleTable: {
                visible: incidentType === 'insured',
                data: vehicleTableData,
                onRowClick: onRowClick
            },
            vehicleTableHorizontalLine: {
                visible: incidentType !== 'third_party'
            },
            hasIncident: {
                renderCell: renderIncidentIcon
            },
            itemToBeInsured: {
                visible: LOB_NAME === WT_LOB_NAME
            },
            insuredItem: {
                visible: LOB_NAME === RT_LOB_NAME
            },
            vinNumber: {
                visible: LOB_NAME !== WT_LOB_NAME && LOB_NAME !== RT_LOB_NAME
            },
            thirdPartyVehicleTable: {
                visible: incidentType === 'third_party',
                data: vehicleTableData,
                onRowClick: onRowClick,
                onSelectionChange: (selectedIds) => setSelectedVehiclePublicIDs(selectedIds),
                selectedRows: selectedVehiclePublicIDs
            },
            vehicleDetailCard: {
                visible: !!currentRow && incidentType !== 'third_party'
            },
            thirdPartyVehicleDetailCard: {
                visible: !!currentRow && incidentType === 'third_party'
            },
            thirdPartyVehicleDetailField: {
                propertyOwnerContacts: incidentType === 'third_party' ? propertyOwnerContacts : (vehicleOwnerContacts || propertyOwnerContacts)
            },
            vehicleDetailField: {
                visible: LOB_NAME === PA_LOB_NAME || LOB_NAME === CA_LOB_NAME,
                selectedDriverId
            },
            rtVehicletDetailField: {
                visible: LOB_NAME === RT_LOB_NAME,
                selectedDriverId
            },
            watercraftDetailField: {
                visible: LOB_NAME === WT_LOB_NAME,
                selectedDriverId
            },
            addVehicleButtonTips: {
                visible: incidentType !== 'third_party',
                content: getAddVehicleBtnInfo().tip
            }
        
    }), [claimNumber, currentRow, writeValue, onValidate, authHeader, claimVM, isExistingPolicyVehicle, handleCancel, VehicleService, availableDrivers, availablePassengers, handleContactSelectionChange, incidentType, searchValue, filterBySearchValue, isEditing, addIncidentVehicle, getAddVehicleBtnInfo, selectedVehiclePublicIDs, removeIncidentVehicles, renderModelFilter, vehicleTableData, onRowClick, renderIncidentIcon, LOB_NAME, vehicleOwnerContacts, propertyOwnerContacts, selectedDriverId, saveAndClose]);

    const resolvers = {
        resolveComponentMap: {
            vehicledetails: VehicleDetails,
            watercraftdetails: WatercraftDetails,
            rtVehicledetails: RTVehicleDetails,
            thirdPartyVehicledetails: ThirdPartyVehicleDetails,
            buttonwithtips: ButtonWithTips,
        },
        resolveCallbackMap: {
            renderThirdPartyActionCell: renderThirdPartyActionCell
        },
        resolveClassNameMap: styles,
    };

    const readValue = useCallback((id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            currentRow,
            id,
            path,
            generageOverrides
        );
    }, [currentRow, generageOverrides]);

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={currentRow}
            overrideProps={generageOverrides}
            onModelChange={updateCurrentRow}
            onValueChange={writeValue}
            resolveValue={readValue}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyVehicle.propTypes = {
    incidentType: PropTypes.string.isRequired
}
PolicyVehicle.defaultProps = {
    incidentType: 'insured'
};

export default PolicyVehicle;
