import { Button } from '@jutro/components';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { IMValuablePapersService } from 'wni-capability-quoteandbind-im';
import { WniCheckboxField, WniDataTable } from 'wni-common-base-components';
import ValuablePaperForm from './component/ValuablePaperForm/ValuablePaperForm';
import messages from './ValuablePapersTable.messages';

const ValuablePapersTable = (props) => {
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const validationID = 'ValuablePapersTable'

    const { isComponentValid, registerComponentValidation } = useValidation(validationID);

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const {
        jobID,
        sessionUUID,
        setCoveragePart,
        isEditable,
        availableBuildings = [],
        valuablePapers = [],
        onValidate: onContainerValidate,
        showErrors,
    } = props;

    const [selectedValuablePaperPublicIDs, setSelectedValuablePaperPublicIDs] =
        useState([]);

    const [openedValuablePaper, setOpenedValuablePaper] = useState();

    const isAllValuablePapersValid = valuablePapers.every((valuablePaper) => {
        const {
            locationBuildingPublicID,
            isLocation,
        } = valuablePaper
        if (_.isNil(locationBuildingPublicID) || _.isNil(isLocation)) {
            return false
        }
        return true
    })
    
    useEffect(() => {
        registerComponentValidation(() => _.isNil(openedValuablePaper) && isAllValuablePapersValid)
    }, [isAllValuablePapersValid, openedValuablePaper, registerComponentValidation])


    useEffect(() => {
        if (onContainerValidate) {
            onContainerValidate(isComponentValid, validationID)
        }
        return () => {
            if (onContainerValidate) {
                onContainerValidate(true, validationID)
            }
            
        }
    }, [isComponentValid, onContainerValidate])

    const delValuablePapers = async () => {
        const newCoveragePart = await withLoadingMask(() =>
            IMValuablePapersService.deleteValuablePapers(
                jobID,
                sessionUUID,
                selectedValuablePaperPublicIDs,
                authHeader
            )
        );
        setCoveragePart(newCoveragePart);
        setSelectedValuablePaperPublicIDs([]);
        setOpenedValuablePaper(undefined);
    };

    const addValuablePaper = async () => {
        const newValuablePaper = await withLoadingMask(() =>
            IMValuablePapersService.getNewNonPersistableValuablePaper(
                jobID,
                sessionUUID,
                authHeader
            )
        );

        setOpenedValuablePaper(newValuablePaper);
    };

    const handleValuablePaperCancel = () => {
        setOpenedValuablePaper(undefined)
    }
    const handleSaveValuablePaper = async (updatedValuablePaper) => {

        setOpenedValuablePaper(undefined)
        const newCoveragePart = await withLoadingMask(() =>
            IMValuablePapersService.saveValuablePaper(
                jobID,
                sessionUUID,
                updatedValuablePaper,
                authHeader
            )
        );
        setCoveragePart(newCoveragePart)
    }

    return (
        <>
            {isEditable && (
                <Flex gap="small" justifyContent="right" className="mb-10">
                    <Button
                        className="wni-button-danger"
                        disabled={selectedValuablePaperPublicIDs.length === 0}
                        onClick={delValuablePapers}
                        label={messages.scheduleDel}
                    />
                    <Button icon="gw-add" onClick={addValuablePaper} label={messages.scheduleAdd} />
                </Flex>
            )}
            <div className="table-wrapper mb-10">
                <WniDataTable
                    id="table_IMValuablePapers"
                    data={valuablePapers}
                    showSearch={false}
                >
                    <DisplayColumn
                        renderHeader={() => {
                            return isEditable && valuablePapers.length > 0? (
                                <WniCheckboxField
                                    value={
                                        !valuablePapers.some(
                                            (valuablePaper) =>
                                                !selectedValuablePaperPublicIDs.includes(
                                                    valuablePaper.publicID
                                                )
                                        )
                                    }
                                    label={translator(messages.valuableNumber)}
                                    showInlineLabel
                                    onValueChange={(checked) => {
                                        const newSelectedIds = checked
                                            ? valuablePapers.map(
                                                  (valuablePaper) =>
                                                      valuablePaper.publicID
                                              )
                                            : [];
                                        setSelectedValuablePaperPublicIDs(
                                            newSelectedIds
                                        );
                                    }}
                                />
                            ) : (
                                translator(messages.valuableNumber)
                            );
                        }}
                        sortable={false}
                        textAlign="left"
                        renderCell={(valuablePaper) => {
                            return isEditable ? (
                                <WniCheckboxField
                                    value={selectedValuablePaperPublicIDs.includes(
                                        valuablePaper.publicID
                                    )}
                                    label={valuablePaper.valuableNumber}
                                    showInlineLabel
                                    onValueChange={(checked) => {
                                        let newSelectedScheduleItemNumbers;
                                        if (checked) {
                                            newSelectedScheduleItemNumbers =
                                                selectedValuablePaperPublicIDs.concat(
                                                    [valuablePaper.publicID]
                                                );
                                        } else {
                                            newSelectedScheduleItemNumbers =
                                                selectedValuablePaperPublicIDs.filter(
                                                    (id) =>
                                                        id !==
                                                        valuablePaper.publicID
                                                );
                                        }
                                        setSelectedValuablePaperPublicIDs(
                                            newSelectedScheduleItemNumbers
                                        );
                                    }}
                                />
                            ) : (
                                valuablePaper.valuableNumber
                            );
                        }}
                    />
                    <DisplayColumn
                        header={translator(messages.locationBuilding)}
                        sortable={false}
                        textAlign="left"
                        renderCell={(item) => {
                            const building = availableBuildings.find(
                                (b) =>
                                    b.publicID === item.locationBuildingPublicID
                            );
                            if(!building) {
                                return '-';
                            }
                            return `${_.get(
                                building,
                                'locationDisplayName'
                            )}/${_.get(building, 'displayName')}`;
                        }}
                    />
                    <DisplayColumn
                        header={translator(messages.isLocationALibrary)}
                        sortable={false}
                        textAlign="left"
                        path="isLocation"
                        renderCell={(item) =>
                            item.isLocation
                                ? translator(messages.Yes)
                                : translator(messages.No)
                        }
                    />
                    <DisplayColumn
                        header={translator(messages.receptacleManufacturer)}
                        sortable={false}
                        textAlign="left"
                        path="receptacleManufacturer"
                    />
                    <DisplayColumn
                        header={translator(messages.receptacleLabel)}
                        sortable={false}
                        textAlign="left"
                        path="receptacleLabel"
                    />
                    <DisplayColumn
                        header={translator(messages.receptacleClass)}
                        sortable={false}
                        textAlign="left"
                        renderCell={(item) => {
                            const classOption = item.receptacleClassRange.find(
                                (opt) =>
                                    opt.code === item.receptacleClassOptionCode
                            );
                            return _.get(classOption, 'name');
                        }}
                    />
                    <DisplayColumn
                        header={translator(messages.recptacleIssue)}
                        sortable={false}
                        textAlign="left"
                        path="recptacleIssue"
                    />
                    <DisplayColumn
                        header={translator(messages.UnscheduledLimit)}
                        sortable={false}
                        textAlign="left"
                        path="blanketLimit"
                        fieldDatatype="number"
                    />
                    <DisplayColumn
                        header={translator(messages.ScheduledLimit)}
                        sortable={false}
                        textAlign="left"
                        path="totalLimit"
                        fieldDatatype="number"
                    />
                    <DisplayColumn
                        key="ActionColumn"
                        sortable={false}
                        textAlign="left"
                        renderCell={(valuablePaper) => {
                            return (
                                <Button
                                    className="btn-link"
                                    onClick={() =>
                                        setOpenedValuablePaper(valuablePaper)
                                    }
                                    label={`${translator(
                                        messages.scheduleEdit
                                    )}/${translator(messages.scheduleView)}`}
                                />
                            );
                        }}
                        visible={isEditable}
                    />
                </WniDataTable>
            </div>
            {openedValuablePaper && (
                <>
                    <ValuablePaperForm
                        valuablePaper={openedValuablePaper}
                        handleValuablePaperCancel={handleValuablePaperCancel}
                        handleSaveValuablePaper={handleSaveValuablePaper}
                        availableBuildings={availableBuildings}
                        isEditable={isEditable}
                    />
                    {showErrors && <div className='font-error-light-16 mb-10 mt--10' >{translator(messages.itemFormNotClosedMessage)}</div>}
                </>
            )}
            {showErrors && !isAllValuablePapersValid && <div className='font-error-light-16 mb-10 mt--10' >{translator(messages.valuablePapersInvalidMessage)}</div>}

        </>
    );
};

export default ValuablePapersTable;
