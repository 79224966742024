import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { WniDataTable } from 'wni-common-base-components';
import { Flex, FlexItem } from '@jutro/layout';
import { Button, InputField } from '@jutro/legacy/components';
import messages from '../../CPRisksPage.messages';

const pageConfig = {
    page: 0,
    pageSize: 5
};
function SearchClassCodeDescriptionPopup(props) {
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        tableData,
        oldRatingType,
        isClassDescriptionSearch,
        isSpecialClass,
        isShowRatingType
    } = props;

    const translator = useTranslator();
    const [ratingType, updateRatingType] = useState(oldRatingType);
    const [classCode, updateClassCode] = useState(null);
    const [classDescription, updateClassDescription] = useState('');
    const [searchedClassCodeData, updateSearchedClassCodeData] = useState(tableData);
    const [tablePageConfig, updateTablePageConfig] = useState(pageConfig);

    useEffect(() => {
        if (!_.isNil(oldRatingType)) {
            const filteredTable = tableData?.filter(elt => elt.RatingType === oldRatingType);
            updateSearchedClassCodeData(filteredTable);
        }
        // first enter filter out by rating type, no need dependencies
    }, [])

    const searchClassCode = () => {
        let newTableData;
        if (!_.isNil(ratingType) && (ratingType !== '')) {
            newTableData = tableData?.filter(elt => (elt.RatingType)?.toLowerCase() === ratingType?.toLowerCase());
        } else {
            newTableData = tableData;
        }

        if (!_.isNil(classCode) && (classCode !== '')) {
            if (isClassDescriptionSearch) {
                newTableData = newTableData?.filter(elt => elt.ClassCodeBldg === classCode);
            } else {
                newTableData = newTableData?.filter(elt => elt.Value === classCode);
            }
        }

        if (!_.isNil(classDescription) && (classDescription !== '')) {
            if (isClassDescriptionSearch) {
                newTableData = newTableData?.filter(elt => elt.Value?.toLowerCase().includes(classDescription?.toLowerCase()));
            }
            if (isSpecialClass) {
                // special class class code search also support class description search
                newTableData = newTableData?.filter(elt => elt.SpecialClassDescription?.toLowerCase().includes(classDescription?.toLowerCase()));
            } 
        }
        updateTablePageConfig(pageConfig);
        updateSearchedClassCodeData(newTableData);
    }

    const onConfigChange = (obj) => {
        updateTablePageConfig(obj);
    };

    const handleSelect = (item) => {
        // updateSelectedCode(item);
        onResolve(item);
    };

    const resetChoice = () => {
        updateRatingType(null);
        updateClassCode(null);
        updateClassDescription(null);
        updateSearchedClassCodeData([]);
        updateTablePageConfig(pageConfig);
    }

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={(isClassDescriptionSearch && !isSpecialClass) ?  translator(messages.classDescriptionSearch): translator(messages.classCodeSearch)}/>
            <ModalBody id="searchClassCodeDescription" autoFocus={false}>
                <InputField
                    id="ratingTypeInput"
                    label="Rating Type"
                    labelPosition="left"
                    onValueChange={(value) => updateRatingType(value)}
                    value={ratingType}
                    visible={isShowRatingType}
                />
                <InputField
                    id="classCodeInput"
                    label="Class Code"
                    labelPosition="left"
                    onValueChange={(value) => updateClassCode(value)}
                    value={classCode}
                />
                {(isClassDescriptionSearch || isSpecialClass) && 
                    <InputField
                        id="classDescriptionInput"
                        label="Class Description"
                        labelPosition="left"
                        onValueChange={(value) => updateClassDescription(value)}
                        value={classDescription}
                    />
                }
                <Flex justifyContent="left">
                    <FlexItem><Button onClick={searchClassCode}>Search</Button></FlexItem>
                    <FlexItem><Button onClick={resetChoice} type="outlined">Reset</Button></FlexItem>
                </Flex>
                <WniDataTable
                    columnsConfigurable={false}
                    data={searchedClassCodeData}
                    config={tablePageConfig}
                    onConfigChange={onConfigChange}
                    pageSizeOptions={[5, 10, 15, 20]}
                    id="searchClassCodesTable"
                    showPagination
                    showSearch={false}
                    >
                    <DisplayColumn
                        id="select"
                        textAlign="left"
                        columnProportion="3"
                        renderCell={(item) => {
                            const buttonDom = <Button onClick={() => handleSelect(item)} >Select</Button>
                            return WniTableRowUtil.renderCell(item.classCode, buttonDom)
                        }}
                        sortable={false}
                    />
                    <DisplayColumn
                        header="Rating Type"
                        id="ratingType"
                        path="RatingType"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                        visible={isShowRatingType}
                    />
                    <DisplayColumn
                        header="Class Code"
                        id="classCode"
                        path="Value"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                        visible={!isClassDescriptionSearch}
                    />
                    <DisplayColumn
                        header="Class Code"
                        id="classCode"
                        path="ClassCodeBldg"
                        textAlign="left"
                        columnProportion="3"
                        sortable
                        visible={isClassDescriptionSearch}
                    />
                    <DisplayColumn
                        header="Class Description"
                        id="classDescription"
                        path="Value"
                        textAlign="left"
                        columnProportion="5"
                        sortable
                        visible={isClassDescriptionSearch && !isSpecialClass}
                    />
                    <DisplayColumn
                        header="Class Description"
                        id="classDescription"
                        path="SpecialClassDescription"
                        textAlign="left"
                        columnProportion="5"
                        sortable
                        visible={isSpecialClass}
                    />
            </WniDataTable>
            </ModalBody>
            <ModalFooter contentLayout={{
                    component: 'flex',
                    componentProps: {
                        alignItems: "middle",
                        justifyContent: "right",
                        wrap: 'reverse',
                        gap: 'medium'
                    }
                }}>
                <Button onClick={()=>{onReject(ratingType)}} type="outlined">Cancel</Button>
            </ModalFooter>
        </ModalNext>
    );
}
SearchClassCodeDescriptionPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    isShowRatingType: PropTypes.bool,
    isSpecialClass: PropTypes.bool
};

SearchClassCodeDescriptionPopup.defaultProps = {
    size: 'md',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    isShowRatingType: false,
    isSpecialClass: false
};

export default SearchClassCodeDescriptionPopup;
