import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Icon } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import {
    WindowUtil,
    QuoteUtil,
    ValidationIssueUtil,
} from 'wni-portals-util-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { CPBlanketsService } from 'wni-capability-quoteandbind-cp';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import WizardPage from '../../templates/CPWizardPage';
import CPBlanketDetailsComponent from './Components/CPBlanketDetailsComponent/CPBlanketDetailsComponent';

import metadata from './CPBlanketsPage.metadata.json5';
import messages from './CPBlanketsPage.messages';

const PATH = 'lobData.commercialProperty.blankets';
const DTO_PATH = 'wni.edge.capabilities.quote.lob.commercialproperty.dto.CPBlanketDTO';
const xCenter = 'pc';
const CHILDREN_PATH = `${PATH}.children`;
const VALIDATION_ICON_MAP = {
    success: 'gw-check-circle',
    warning: 'gw-warning',
    error: 'gw-error',
};

function CPBlanketsPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        readOnly,
        cpBlanketsService,
        isPolicyChange,
    } = props;

    const {
        jobID, sessionUUID,
        lobData: {
            commercialProperty: {
                availableBlanketCovPatterns
            }
        },
    } = submissionVM.value;

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
        registerComponentValidation
    } = useValidation('CPBlanketsPage');

    const blanketsVM = _.get(submissionVM, 'lobData.commercialProperty.blankets');

    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);

    const [blanketsVMState, updateBlanketsVMState] = useState(blanketsVM);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [currentRow, updateCurrentRow] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [blanketCovPatterns, updateBlanketCovPatterns] = useState(availableBlanketCovPatterns);
    const [riskTreeRows, updateRiskTreeRows] = useState([]);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'rowIdPath') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'blanketsTable');
    };

    const findItemVMValid = (vm) => {
        if (!vm.aspects.valid || !vm.aspects.subtreeValid) {
            return false;
        }
        const coverageVisible = _.get(vm, 'value.coverageVisible');
        const selectedCoverages = _.get(vm, 'value.selectedCoverages');
        if (coverageVisible && selectedCoverages.length <= 0) {
            return false;
        }
        const displayableValid = _.get(vm.value, 'blanketDisplayables', []).every((displayable) => displayable.isValid);
       
        return displayableValid;
    };

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
    }, [invalidFields]);

    const formatDateValue = (displayables) => {
        return _.map(displayables, (displayable) => {
            if (displayable.inputSetMode === 'Date' && _.isString(displayable.dateValue)) {
                const newDate = new Date(displayable.dateValue);
                const newDateValue = {
                    day: newDate.getDate(),
                    month: newDate.getMonth(),
                    year: newDate.getFullYear()
                }
                _.set(displayable, 'dateValue', newDateValue);
            }
            return displayable
        })
    }

    const updateSubmissionVMByResponse = (response) => {
        if (!_.isEmpty(response.currentBlanket)) {
            const newRiskTreeRows = _.get(response, 'currentBlanket.riskTreeRows');
            const newBlanketVM = viewModelService.create(response.currentBlanket, xCenter, DTO_PATH);
            updateRiskTreeRows(newRiskTreeRows)
            updateCurrentRow(newBlanketVM);
        } else {
            updateCurrentRow(null);
        }

        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, 'lobData.commercialProperty.availableBlanketCovPatterns', response.availableBlanketCovPatterns);
        _.set(newSubmissionVM, 'lobData.commercialProperty.blankets', response.blankets);
        _.set(newSubmissionVM, 'lobData.commercialProperty.riskTreeRows', response.riskTreeRows);
        const displayMssages = _.get(response, 'errorsAndWarnings.serverIssues_Ext.displayMssages');
        updateValidationIssues(displayMssages);

        updateBlanketCovPatterns(response.availableBlanketCovPatterns);
        updateBlanketsVMState(_.get(newSubmissionVM, PATH));
        updateWizardData(newSubmissionVM);

        return newSubmissionVM;
    }

    const updateSubmission = (currentVM) => {
        const rowIdPath = _.get(currentVM.value, 'rowIdPath');
        const allData = _.get(submissionVM.value, PATH);
        const currentIndex = allData.findIndex((item) => item.rowIdPath === rowIdPath);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${PATH}[${currentIndex}]`, currentVM.value);
        return newSubmissionVM;
    };

    const updateSubmissionVMByCurrentRow = (rowData, updateDataFunc) => {
        if (!rowData) {
            updateCurrentRow(rowData);
            return false;
        }
        const initCurrentRow = viewModelService.create(rowData.value, xCenter, DTO_PATH);
        updateCurrentRow(initCurrentRow);
        if (!readOnly) {
            const newSubmissionVM = updateSubmission(initCurrentRow);
            updateBlanketsVMState(_.get(newSubmissionVM, PATH));
            updateDataFunc(newSubmissionVM)
        }

    }

    const updateService = async (currentVM) => {
        if (readOnly) {
            return;
        }
        const newBlanket = currentVM.value;
        _.set(newBlanket, 'blanketDisplayables', formatDateValue(newBlanket.blanketDisplayables));
        const requestData = {
            jobID,
            sessionUUID,
            blanket: newBlanket
        }
        setLoadingMask(true);
        const res = await cpBlanketsService.updateBlanket(requestData, authHeader);

        const rowIdPath = _.get(currentVM.value, 'rowIdPath');
        const currentBlanket = res.blankets.find((item) => item.rowIdPath === rowIdPath);
        _.set(currentRow, 'value', currentBlanket);
        updateBlanketCovPatterns(res.availableBlanketCovPatterns);
        updateSubmissionVMByResponse(res);
        updateSubmissionVMByCurrentRow(currentRow, updateWizardSnapshot);
        setLoadingMask(false);
    }

    const updateSelectedCoverages = (selectedCov) => {
        const newCurrentRow = viewModelService.clone(currentRow);
        _.set(newCurrentRow, 'selectedCoverages', selectedCov);
        updateCurrentRow(newCurrentRow)
        if (!isComponentValid || selectedCov.length === 0) {
            handleValidation();
            return;
        };
        updateService(newCurrentRow);
    }

    const onRowClick = async (item) => {
        const currentPublicID = _.get(currentRow, 'value.publicID');
        if (currentPublicID === item.publicID) {
            return false;
        }

        const requestData = {
            jobID,
            sessionUUID,
            blanketID: item.publicID
        }
        setLoadingMask(true);
        const resBlanket = await cpBlanketsService.getBlanketDetails(requestData, authHeader);

        const initCurrentRow = viewModelService.create(resBlanket, xCenter, DTO_PATH);
        updateCurrentRow(initCurrentRow);
        updateRiskTreeRows(resBlanket.riskTreeRows)
        WindowUtil.scrollTo('blanketDetailContainer')
        setLoadingMask(false);

        // cache riskTreeRows so that don't have to call service erery time
        const blanketIndex = _.findIndex(blanketsVM.value, (blanket) => blanket.publicID === item.publicID);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(initCurrentRow, 'value.riskTreeRows', resBlanket.riskTreeRows);
        _.set(newSubmissionVM, `value.${PATH}[${blanketIndex}]`, initCurrentRow.value);
        updateSubmission(newSubmissionVM);
        updateBlanketsVMState(_.get(newSubmissionVM, PATH));
    };

    const writeValue = (value, path) => {
        if (currentRow && !readOnly) {
            updateSubmissionVMByCurrentRow(currentRow, updateWizardData);
        }
    };

    const addBlanket = async (item) => {
        const requstData = {
            jobID,
            sessionUUID,
            blanketType: item
        }
        setLoadingMask(true);
        const res = await cpBlanketsService.addBlanket(requstData, authHeader);
        updateSubmissionVMByResponse(res);
        updateShowErrors(false);
        setLoadingMask(false);
    }

    const removeBlankets = async () => {
        if (_.isEmpty(selection)) {
            return;
        }
        modalApi.showConfirm({
            title: messages.removeTitle,
            message: messages.removeDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async (result) => {
            if (result === 'cancel' || result === 'close') {
                return false;
            }
            const requstData = {
                jobID,
                sessionUUID,
                publicIDs: selection
            }
            setLoadingMask(true);
            const res = await cpBlanketsService.removeBlankets(requstData, authHeader);
            updateSubmissionVMByResponse(res);
            updateSelection([]);
            setLoadingMask(false);
        })
    }

    const generateBlanketCovPatternItemsOverrides = () => {
        const overrides = blanketCovPatterns.map((item, index) => {
            return {
                [`blanketCovPatternItem${index}`]: {
                    onClick: () => addBlanket(item)
                }
            };
        });
        return Object.assign({}, ...overrides);
    };

    const cancel = () => {
        updateCurrentRow(null);
        updateShowErrors(false);
    }

    const saveAndNext = async () => {
        if (!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        await updateService(currentRow);
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        if (childrenVM.length < 2) {
            return true;
        }
        let index = _.findIndex(childrenVM, (vm) => vm.value.rowIdPath === currentRow.value.rowIdPath);
        if (index === childrenVM.length - 1) {
            index = 0;
        } else {
            index += 1;
        }
        onRowClick(childrenVM[index].value);
        updateCurrentRow(childrenVM[index]);
    }

    const saveAndClose = async () => {
        if (!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid) {
            handleValidation();
            return false;
        };
        await updateService(currentRow);
        updateCurrentRow(null);
    }

    const applyRisks = async (selectedRisks) => {
        const requestData = {
            jobID,
            sessionUUID,
            blanket: currentRow.value,
            selectedRisks
        }
        setLoadingMask(true);
        const res = await cpBlanketsService.applyRisks(requestData, authHeader);
        const pcDisplayMessage = _.get(res, 'errorsAndWarnings.pcDisplayMessage_Ext');
        if (_.isEmpty(pcDisplayMessage)) {
            updateSubmissionVMByResponse(res);
            updateRiskTreeRows(res.currentBlanket.riskTreeRows);
        }
        setLoadingMask(false);
        return res;
    }

    const removeAllRisks = async () => {
        modalApi.showConfirm({
            title: messages.removeAllRisksTitle,
            message: messages.removeAllRisksDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async (result) => {
            if (result === 'cancel' || result === 'close') {
                return false;
            }
            const requestData = {
                jobID,
                sessionUUID,
                blanket: currentRow.value,
                selectedRisks: []
            }
            setLoadingMask(true);
            const res = await cpBlanketsService.removeAllRisks(requestData, authHeader);
            const pcDisplayMessage = _.get(res, 'errorsAndWarnings.pcDisplayMessage_Ext');
            if (_.isEmpty(pcDisplayMessage)) {
                updateSubmissionVMByResponse(res);
                updateRiskTreeRows(res.currentBlanket.riskTreeRows);
            }
            setLoadingMask(false);
            return res;
        })
    }

    const renderBlanketTypeCell = (item) => {
        if (item.blanketType === 'Blanket Coverage') {
            return 'Standard Blanket'
        }
        return item.blanketType;
    };

    const allBlanketsValid = () => {
        const allVehicleVMs = _.get(submissionVM, CHILDREN_PATH);
        return allVehicleVMs.every((vm) => findItemVMValid(vm));
    };

    const onValidationIconCell = (item, index) => {
        const childrenVM = _.get(submissionVM, CHILDREN_PATH);
        const itemVM = childrenVM.find((v) => v.value.rowIdPath === index) || {};
        const isItemValid = findItemVMValid(itemVM);
        const type = isItemValid ? 'success' : 'error';
        const iconDom = <Icon id={`validationIcon${item.rowIdPath}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.rowIdPath, iconDom)
    }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                labelPosition: 'left',
            },
            delBlankets: {
                disabled: _.isEmpty(selection),
                visible: !readOnly
            },
            addBlankets: {
                visible: !readOnly
            },
            itemData: {
                data: blanketCovPatterns
            },
            blanketsItem: {
                onClick: (item) => addBlanket(item)
            },
            blanketsTable: {
                data: blanketsVMState.value,
                onRowClick,
                onSelectionChange: (rows) => updateSelection(rows),
                selectionType: readOnly ? 'none' : 'multi',
                selectedRows: selection,
            },
            validationIcon: {
                renderCell: onValidationIconCell
            },
            blanketType: {
                renderCell: renderBlanketTypeCell
            },
            blanketDetailContainer: {
                visible: !!currentRow
            },
            blanketDetails: {
                blanketVM: currentRow,
                updateSubmissionVMByCurrentRow,
                updateService,
                updateWizardData,
                selectedCoverages: _.get(currentRow, 'value.selectedCoverages'),
                updateSelectedCoverages,
                riskTreeRows,
                applyRisks,
                removeAllRisks,
                showErrors,
                onValidate,
                readOnly
            },
            saveNextButton: {
                // visible: blanketsVMState.value.length > 1,
                visible: false,
                content: readOnly ? translator(messages.nextBlanket) : translator(messages.saveAndNextBlanket)
            },
            saveButton: {
                content: readOnly ? translator(messages.close) : translator(messages.saveAndClose)
            },
            ...generateBlanketCovPatternItemsOverrides()
        };
    }, [showErrors, submissionVM, currentRow, selection, blanketCovPatterns, riskTreeRows, blanketsVMState, isComponentValid]);

    const onPageNext = useCallback(async () => {
        if (readOnly) {
            return submissionVM;
        }
        if(!allBlanketsValid()) {
            return false;
        }
        const {
            onPreQuotePageNext, // = onDefaultPageNext,
        } = props;


        const res = await cpBlanketsService.onPageNext({ jobID, sessionUUID }, authHeader);

        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);
        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if (hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }

        //
        let retval = updateSubmissionVMByResponse(res);

        if (_.isFunction(onPreQuotePageNext)) {
            retval = await onPreQuotePageNext();
        }
        return retval;
    }, [authHeader, submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
            cancel,
            removeBlankets,
            saveAndNext,
            saveAndClose
            
        },
        resolveComponentMap: {
            cpblanketdetailscomponent: CPBlanketDetailsComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(_.stubTrue)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
            disableNext={!allBlanketsValid()}
            showNext={!currentRow}
            showPrevious={!currentRow}
            showCancel={!currentRow}
            showRequiredInfoForFasterQuote
            alwaysCallOnNext={!readOnly}
            isPolicyChange={isPolicyChange}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CPBlanketsPage.propTypes = {
    ...wizardProps,
    cpBlanketsService: PropTypes.object
};

CPBlanketsPage.defaultProps = {
    cpBlanketsService: CPBlanketsService
};
export default CPBlanketsPage;