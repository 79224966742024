import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { CAPolicyDetailsService } from 'wni-capability-quoteandbind-ca';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import {
    ValidationIssueUtil,
    IssuanceValidationUtil,
    WindowUtil,
    QuoteUtil,
    WniDateUtil,
} from 'wni-portals-util-js';
import { useProductsData } from 'wni-portals-util-react';
import {
    AgencyAndProducerInfoComponent,
    PrimaryNamedInsuredInputPopup,
    PolicyDBAComponent,
    ANIComponent
} from 'wni-capability-gateway-react';
import moment from 'moment';

import WizardPage from '../../templates/CAWizardPage';

import styles from './CAPolicyDetailsPage.module.scss';
import metadata from './CAPolicyDetailsPage.metadata.json5';
import messages from './CAPolicyDetailsPage.messages';

function CAPolicyDetailsPage(props) {

    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        checkRequiredForIssuance,
        savePolicyDetailsData,
        wizardPageData,
        caPolicyDetailsService
    } = props;

    const requiredForIssuranceAllFields = ['effectiveDate'];
    const policyDetailPath = 'lobData.commercialAuto.policyDetails_Ext';


    const {
        jobID: quoteID,
        sessionUUID,
        baseData,
        lobData: {
            commercialAuto: {
                policyDetails_Ext: {
                    displayableDTO
                }
            }
        },
        // bindData,
        // quoteData,
    } = submissionVM.value;

    const {
        baseState_Ext: baseState,
        primaryNamedInsured_Ext: primaryNamedInsured,
        termType,
        productCode
    } = baseData;
    const pnidbalist = _.get(submissionVM, 'baseData.pnidbalist_Ext');

    // const history = useHistory();
    const isRequiredForIssuance = checkRequiredForIssuance && IssuanceValidationUtil.isRequiredForIssuanceR2(baseData, wizardPageData);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const { getAvailableEffectiveDate } = useProductsData();
    const {
        initialValidation,
        registerComponentValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('CAPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [validationIssues, updateValidationIssues] = useState(undefined);
    // const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [showPNIInfoMessage, updateShowPNIInfoMessage] = useState(false);
    const CLASS_CODE_LIST = [5092, 5091, 5098, 5090, 7217, 7218, 7059, 7480, 7490, 7060, 7481, 7491]
    const availableEffectiveDate = getAvailableEffectiveDate(productCode, baseState);
    const isEffectiveDateValid = useCallback(() => {
        const effectiveDate = _.get(submissionVM.value, 'baseData.effectiveDate_Ext');
        const minDate = availableEffectiveDate;
        const dif = moment(effectiveDate).startOf('day').toDate().getTime() - moment(minDate).startOf('day').toDate().getTime();

        return dif >= 0;
    }, [submissionVM]);

    const isCurrentPageValid = useCallback(() => {
        return isEffectiveDateValid()
    }, [isEffectiveDateValid]);

    useEffect(() => {
        registerComponentValidation(isCurrentPageValid);
    }, [registerComponentValidation, isCurrentPageValid]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = _.clone(submissionVM);
            // When coverages Form is changed, clear residence type value
            // if (path === 'lobData.homeowners.householdInfoDTO_Ext.coveragesForm') {
            //     _.set(newSubmissionVM.value, 'lobData.homeowners.householdInfoDTO_Ext.residenceType', '')
            // }
            if (path === 'baseData.effectiveDate_Ext') {
                _.set(newSubmissionVM.value, 'baseData.periodStartDate', value)
            }
            _.set(newSubmissionVM, path, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [submissionVM, updateSubmissionVM]
    );

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const showPrimaryNamedInsuredModal = useCallback(
        (primaryNamedInsuredVM, policyNamedInsuredCandidatesVM) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                primaryNamedInsuredVM,
                policyNamedInsuredCandidatesVM,
                showEmployeeFlagToggle: false,
                viewModelService,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                policyDetailsService: caPolicyDetailsService,
                policyDetailPath,
                DBAsVM: pnidbalist,
                // isReadOnly,
                accountContacts: _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
                onValueChange: writeValue,
                size: 'lg'
            };
            return modalApi.showModal(
                <PrimaryNamedInsuredInputPopup {...componentProps} />
            );
        }, [viewModelService, authHeader, pnidbalist]
    );

    const openEditPrimaryNamedInsuredPopup = useCallback(() => {
        const primaryNamedInsuredVM = _.get(submissionVM, 'baseData.primaryNamedInsured_Ext');
        const policyNamedInsuredCandidatesVM = _.get(
            submissionVM,
            'baseData.policyNamedInsuredCandidates_Ext'
        );
        showPrimaryNamedInsuredModal(primaryNamedInsuredVM, policyNamedInsuredCandidatesVM)
            .then((updatedVM) => {
                _.set(submissionVM.value, 'baseData.primaryNamedInsured_Ext', updatedVM.value);

                const newAddress = _.get(updatedVM.value, 'primaryAddress');
                _.set(submissionVM.value, 'baseData.policyAddress', newAddress);

                // Find updated account contact
                const accountContactsVM = _.get(
                    submissionVM,
                    'baseData.accountContacts_Ext'
                );
                const accountHolder = _.get(submissionVM.value, 'baseData.accountHolder');
                const newNamedInuseredVMIndex = _.get(accountContactsVM, 'value', []).findIndex(
                    (accountContactVM) => accountContactVM.publicID === _.get(updatedVM, 'value.publicID')
                );
                _.set(submissionVM.value, `baseData.accountContacts_Ext[${newNamedInuseredVMIndex}]`, updatedVM.value);
                if (accountHolder.publicID === _.get(updatedVM, 'value.publicID')) {
                    _.set(submissionVM.value, 'baseData.accountHolder', updatedVM.value);
                }
                const newSubmissionVM = _.clone(submissionVM);
                updateSubmissionVM(newSubmissionVM);
            }).catch(() => _.noop());
    }, [showPrimaryNamedInsuredModal, submissionVM, updateSubmissionVM]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {
        const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.contactName');
        // const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        // const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${displayName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if (_.isNil(primaryNamedInsured)) {
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode')
            if (_.isNil(primaryAddress)) {
                primaryAddress = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName')
            }
            // primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div>
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.recommendCode')
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div>
        );
    }, [getPrimaryNamedInsuredDisplayName, primaryNamedInsured, submissionVM.value]);


    const getDisplayableFieldValue = useCallback((label) => {
        const displayableValue = displayableDTO
            .find((elt) => elt.label === label);
        return _.get(displayableValue, 'rangeValue')
        // return displayableValue.rangeValue;
    }, [displayableDTO])


    const getDisplayableDropdownOptions = useCallback((label) => {
        const displayableValue = displayableDTO.find((elt) => elt.label === label);
        // if (label ==="Policy Type") {
        //     const options = displayableValue.rangeValueOptions
        //     options.unshift('Motor Carrier Coverage form')
        //     options.map((elt) => {
        //         return {
        //             code: elt,
        //             name: elt
        //         }
        //     });
        //     return options;
        // }
        if (!displayableValue) {
            return []
        }
        const options = displayableValue.rangeValueOptions
            .map((elt) => {
                return {
                    code: elt,
                    name: elt
                }
            });
        return options;

    }, [displayableDTO])

    const onDisplayablesValueChange = useCallback(
        (value, label) => {
            const newSubmissionVM = _.clone(submissionVM);
            const index = displayableDTO.findIndex((elt) => elt.label === label);
            const valuePath = `lobData.commercialAuto.policyDetails_Ext.displayableDTO[${index}].rangeValue`
            const updateTogglePath = `lobData.commercialAuto.policyDetails_Ext.displayableDTO[${index}].updated`
            _.set(newSubmissionVM.value, updateTogglePath, true)
            _.set(newSubmissionVM.value, valuePath, value);
            updateSubmissionVM(newSubmissionVM);
        },
        [displayableDTO, submissionVM, updateSubmissionVM]);

    useEffect(() => {
        if (getDisplayableFieldValue('Policy Type') !== 'Business Auto Coverage Form') {
            onDisplayablesValueChange('Business Auto Coverage Form', 'Policy Type')
        }
    }, [])

    const shouldShowAndRequireVehicleType = useCallback(() => {
        const grossReceiptsBasisOrMileageBasis = displayableDTO.find((elt) => elt.label === 'Gross Receipts Basis Or Mileage Basis');
        if (_.get(grossReceiptsBasisOrMileageBasis, 'rangeValue') === 'Yes') {
            return true
        }
        return false
    }, [displayableDTO])

    const shouldShowAndRequireClassCode = useCallback(() => {
        const grossReceiptsBasisOrMileageBasis = displayableDTO.find((elt) => elt.label === 'Gross Receipts Basis Or Mileage Basis');
        if (_.get(grossReceiptsBasisOrMileageBasis, 'rangeValue') === 'Yes') {
            return true
        }
        return false
    }, [displayableDTO])

    const getClassCodeDropdown = () => {
        return CLASS_CODE_LIST.map((elt) => {
            return {
                code: elt,
                name: elt
            }
        })
    }
    const getFrontendRequiredValiationErrors = useCallback(() => {
        if (!isRequiredForIssuance) {
            return [];
        }
        const newRequiredForIssuranceFields = invalidFields.filter((item) => {
            return requiredForIssuranceAllFields.includes(item);
        });
        return newRequiredForIssuranceFields.map((item) => {
            return {
                type: 'error',
                reason: `Below required fields for issuance are missing: "${translator(messages[item])}"`
            };
        });
    }, [isRequiredForIssuance, invalidFields, requiredForIssuranceAllFields, translator]);


    const handleValidation = useCallback(
        () => {
            const frontendRequiredValiationErrors = getFrontendRequiredValiationErrors();
            // const residenceLocationValidationErrors = getResidenceLocationValidationErrors();
            updateValidationIssues(frontendRequiredValiationErrors);
            // updateValidationIssues(residenceLocationValidationErrors)
            updateShowErrors(true);
            WindowUtil.scrollToInvalidField(invalidFields);
            return false;
        },
        [updateShowErrors, invalidFields]
    );

    const onPageNext = useCallback(async () => {
        setLoadingMask(true);
        const caPolicyDetailsData = _.get(submissionVM.value, 'lobData.commercialAuto.policyDetails_Ext');
        // const questionSetData = _.get(submissionVM.value, 'lobData.workersComp.preQualQuestionSets')
        const quoteBaseData = _.get(submissionVM.value, 'baseData');
        const res = await savePolicyDetailsData(
            quoteID,
            sessionUUID,
            caPolicyDetailsData,
            // questionSetData,
            quoteBaseData,
            authHeader);
        setLoadingMask(false);
        submissionVM.value = res;
        updateSubmissionVM(submissionVM);

        // Next step: move this and the service call into HOWizardPage as a common feature
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if (hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }

        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            return false;
        }
        return submissionVM;
    }, [setLoadingMask, submissionVM, savePolicyDetailsData, quoteID, sessionUUID, authHeader, updateSubmissionVM, displayWarnings]);

    const getAvailableOrganizationTypesOptions = () => {
        const organizationTypesOptions = _.get(submissionVM, `${policyDetailPath}.organizationTypesOptions.aspects.availableValues`);
        return organizationTypesOptions ? organizationTypesOptions.map((option) => {
            return {
                code: option.code,
                name: {
                    id: option.name,
                },
            };
        }) : [];
    };

    const getTermTypeOptions = () => {
        const termTypeOptions = _.get(submissionVM, 'baseData.termType.aspects.availableValues').filter((item) => item.code !== 'HalfYear');
        return termTypeOptions ? termTypeOptions.map((option) => {
            return {
                code: option.code,
                name: {
                    id: option.name,
                },
            };
        }) : [];
    };

    const handleTermTypeChange = useCallback((newTermType, path) => {
        const effectiveDate = _.get(submissionVM, 'baseData.effectiveDate_Ext.value');
        if (newTermType !== 'Other') {
            const newPeriodEndDate = WniDateUtil.getPolicyEndDate(effectiveDate, newTermType);
            _.set(submissionVM.baseData, 'periodEndDate', newPeriodEndDate);
        } else {
            _.set(submissionVM.baseData, 'periodEndDate', '');
        }
        _.set(submissionVM.baseData, 'termType', newTermType);
        updateSubmissionVM(submissionVM);
    }, [submissionVM, updateSubmissionVM]);

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                showRequired: true,
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            effectiveDate: {
                minDate: availableEffectiveDate,
                value: _.get(submissionVM.value, 'baseData.effectiveDate_Ext'),
                showErrors
            },
            agencyAndProducerInfo: {
                model: submissionVM.baseData,
                onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: false,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: true
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate
            },
            primaryNamedInsured: {
                value: getPrimaryNamedInsuredAndDisplay(),
            },
            termType: {
                availableValues: getTermTypeOptions(),
                onValueChange: handleTermTypeChange
            },
            expirationDate: {
                readOnly: termType === 'Annual',
                minDate: LocalDateUtil.today(),
                value: _.get(submissionVM.value, 'baseData.periodEndDate'),
            },
            policyTypeDropdown: {
                readOnly: true,
                value: getDisplayableFieldValue('Policy Type'),
                // availableValues: getDisplayableDropdownOptions('Policy Type'),
                // onValueChange: (value) => onDisplayablesValueChange(value, 'Policy Type')
            },
            individualNamedInsuredDropdown: {
                value: getDisplayableFieldValue('Individual Named Insured On The Policy'),
                availableValues: getDisplayableDropdownOptions('Individual Named Insured On The Policy'),
                onValueChange: (value) => onDisplayablesValueChange(value, 'Individual Named Insured On The Policy')
            },
            legalEntityDropdown: {
                value: getDisplayableFieldValue('Legal Entity'),
                availableValues: getDisplayableDropdownOptions('Legal Entity'),
                onValueChange: (value) => onDisplayablesValueChange(value, 'Legal Entity')
            },
            grossReceiptsBasisOrMileageBasisDropdown: {
                value: getDisplayableFieldValue('Gross Receipts Basis Or Mileage Basis'),
                availableValues: getDisplayableDropdownOptions('Gross Receipts Basis Or Mileage Basis'),
                onValueChange: (value) => onDisplayablesValueChange(value, 'Gross Receipts Basis Or Mileage Basis')
            },
            vehicleTypeDropdown: {
                value: getDisplayableFieldValue('Vehicle Type'),
                availableValues: getDisplayableDropdownOptions('Vehicle Type'),
                onValueChange: (value) => onDisplayablesValueChange(value, 'Vehicle Type'),
                visible: shouldShowAndRequireVehicleType(),
                required: shouldShowAndRequireVehicleType(),
            },
            classCodeDropdown: {
                value: getDisplayableFieldValue('Class Code'),
                availableValues: getClassCodeDropdown(),
                onValueChange: (value) => onDisplayablesValueChange(value, 'Class Code'),
                visible: shouldShowAndRequireClassCode(),
                required: shouldShowAndRequireClassCode(),
            },
            AcceptCertifiedActsDropdown: {
                value: getDisplayableFieldValue('Accept Certified Acts Of Terrorism Coverage'),
                availableValues: getDisplayableDropdownOptions('Accept Certified Acts Of Terrorism Coverage'),
                onValueChange: (value) => onDisplayablesValueChange(value, 'Accept Certified Acts Of Terrorism Coverage'),
            },
            RideSharingDropdown: {
                value: getDisplayableFieldValue('Ride Sharing Arrangements Endorsement Indicator'),
                availableValues: getDisplayableDropdownOptions('Ride Sharing Arrangements Endorsement Indicator'),
                onValueChange: (value) => onDisplayablesValueChange(value, 'Ride Sharing Arrangements Endorsement Indicator'),
            },

            additionalInsuredSection: {
                model: _.get(submissionVM, `${policyDetailPath}.additionalInsureds`),
                onValueChange: writeValue,
                viewModelService,
                industryCode: {
                    code: _.get(primaryNamedInsured, 'industryCode_Ext'),
                    classification: _.get(primaryNamedInsured, 'industryDescription_Ext')
                },
                policyDetailsService: caPolicyDetailsService,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                organizationTypesOptions: getAvailableOrganizationTypesOptions(),
                organizationTypes: _.get(submissionVM, `value.${policyDetailPath}.organizationType`),
                policyDetailPath,
                accountContacts: _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
                policyAddress: _.get(submissionVM, 'value.baseData.policyAddress'),
                existingAddlNamedInsureds: _.get(submissionVM, `value.${policyDetailPath}.unassignedAdditionalNamedInsureds`)
                // isReadOnly
            },
            policyDBA: {
                dbas: _.get(submissionVM, `value.${policyDetailPath}.policyDBAList`),
                // isReadOnly,
                isRequiredForIssuance,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                onValueChange: writeValue,
                policyDetailPath
            },
            primaryNamedInsuredEditIcon: {
                onClick: !showPNIInfoMessage ? () => updateShowPNIInfoMessage(true) : openEditPrimaryNamedInsuredPopup,
            },
            primaryNamedInsuredNotificationInfo: {
                visible: showPNIInfoMessage,
            },

        };
    }, [submissionVM.value, submissionVM.baseData, showErrors, handleAgencyChange, onValidate, getPrimaryNamedInsuredAndDisplay, openEditPrimaryNamedInsuredPopup, getDisplayableFieldValue, getDisplayableDropdownOptions, shouldShowAndRequireVehicleType, getClassCodeDropdown, shouldShowAndRequireClassCode, onDisplayablesValueChange, showPNIInfoMessage]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            anicomponent: ANIComponent,
            policydbacomponent: PolicyDBAComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CAPolicyDetailsPage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    caPolicyDetailsService: PropTypes.func,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: false,
        // uwqReadonly: false
    }
};

CAPolicyDetailsPage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: CAPolicyDetailsService.saveCAPolicyDetailsData,
    caPolicyDetailsService: CAPolicyDetailsService,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: false,
        // uwqReadonly: false
    }
}
export default CAPolicyDetailsPage;