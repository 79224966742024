import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { Claim, fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { ClaimUtil, FNOLLossLocation } from 'gw-capability-fnol-common-react-ext';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { ProductUtil } from 'wni-portals-util-react';
import { FNOLService } from 'wni-capability-fnol';
import PropertyIncidents from './PropertyIncidents/PropertyIncidents';
import metadata from './FNOLPAOtherLossDetailPage.metadata.json5';
import Pedestrians from './Pedestrians/Pedestrians';
import messages from './FNOLPAOtherLossDetailPage.messages';

const {
    PA_LOB_NAME,
    RT_LOB_NAME,
    WT_LOB_NAME,
    CA_LOB_NAME,
    GL_LOB_NAME,
    CPP_LOB_NAME,
} = ProductUtil;

function FNOLPAOtherLossDetailPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: claimVM,
        updateWizardData,
        updateWizardSnapshot,
        authHeader,
        isReadOnly = false
    } = props;
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    // const { FNOLService } = useDependencies('FNOLService');
    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false);
    const [isEditing, updateIsEditing] = useState(false);
    const [involvedValue, updateInvolvedValue] = useState(false);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLPAOtherLossDetailPage');

    const policyType = _.get(claimVM, 'value.policy.policyType', 'personalAuto');
    const lobCode = _.get(claimVM, 'value.policy.lobCode_Ext');
    const LOB_NAME = ClaimUtil.getLobByPolicyType(policyType === 'CommercialPackage' ? lobCode : policyType);

    const {
        lobs: {
            [LOB_NAME]: {
                initalVehicleIncidentData_Ext: initalIncidentData,
                propertyOwnerContacts_Ext: propertyOwnerContacts = [],
                thirdPartyIncidents_Ext: thirdPartyIncidents = [],
                injuryIncidents_Ext: pedestrianIncidents = [],

                // only for GL line
                pedestrianIncidents_Ext: GLPedestrianIncidents = []
            } = {}
        },
        lossCause,
        claimNumber
    } = claimVM.value;

    const getInitPropertyOwnerContact = useCallback(() => {
        return propertyOwnerContacts
    }, [propertyOwnerContacts])

    const relatedContacts = _.get(initalIncidentData, 'contactMap', []);
    const thirdPartyVehicles = thirdPartyIncidents?.map((v) => v.vehicle);

    const propertyIncidentsPath = `lobs.${LOB_NAME}.fixedPropertyIncidents_Ext`;
    const propertyIncidents = _.get(claimVM.value, propertyIncidentsPath, []);    

    const thirdPartyIncidentsPath = `lobs.${LOB_NAME}.thirdPartyIncidents_Ext`;
    const injuryIncidentsPath = `lobs.${LOB_NAME}.injuryIncidents_Ext`;

    const incidentsAddresses = _.get(claimVM.value, 'incidentsAddresses', []);
    const predefinedLossLocations = _.get(claimVM.value, 'predefinedLossLocations');

    useEffect(() => {
        const involved = !_.isEmpty(thirdPartyIncidents);
        updateInvolvedValue(involved);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isVMValid = useCallback((vm) => {
        if (!vm) {
            return true;
        }
        return vm.aspects?.valid && vm.aspects?.subtreeValid;
    }, [])

    const isPageValid = useCallback(() => {
        const thirdPartyIncidentsVM = _.get(claimVM, thirdPartyIncidentsPath);
        const propertyIncidentsVM = _.get(claimVM, propertyIncidentsPath);
        const pedestrianIncidentsVM = _.get(claimVM, injuryIncidentsPath);
        return isVMValid(thirdPartyIncidentsVM) && isVMValid(propertyIncidentsVM) && isVMValid(pedestrianIncidentsVM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimVM]);

    useEffect(() => {
        registerInitialComponentValidation(isPageValid);
    }, [registerInitialComponentValidation, isPageValid]);

    const syncWizardData = useCallback(
        (data, basePath) => {
            const newClaimVM = viewModelService.clone(claimVM);
            _.set(newClaimVM.value, `lobs.${LOB_NAME}.${basePath}_Ext`, data);
            if (!isReadOnly) {
                updateWizardData(newClaimVM);
            }
        },[LOB_NAME, claimVM, isReadOnly, updateWizardData, viewModelService]
    )

    const savePedestrianIncident = useCallback(async (pedestrianIncident) => {
        setLoadingMask(true);
        const response = await FNOLService.savePedestrianIncident(
            claimNumber,
            pedestrianIncident,
            authHeader
        );
        setLoadingMask(false);
        if (!isReadOnly) {
            const newClaimVM = viewModelService.clone(claimVM);
            newClaimVM.value = response;
            updateWizardData(newClaimVM);
            updateWizardSnapshot(newClaimVM);
        }
    }, [setLoadingMask, claimNumber, authHeader, isReadOnly, viewModelService, claimVM, updateWizardData, updateWizardSnapshot])

    const removePedestrianIncident = useCallback(async (pedestrianIncident) => {
        setLoadingMask(true);
        const response = await FNOLService.removePedestrianIncident(
            claimNumber,
            pedestrianIncident.publicID,
            authHeader
        );
        setLoadingMask(false);
        if (!isReadOnly) {
            const newClaimVM = viewModelService.clone(claimVM);
            newClaimVM.value = response;
            updateWizardData(newClaimVM);
            updateWizardSnapshot(newClaimVM);
        }
    },[authHeader, claimNumber, claimVM, isReadOnly, setLoadingMask, updateWizardData, updateWizardSnapshot, viewModelService])

    const savePropertyIncident = useCallback(async (propertyIncident) => {
        setLoadingMask(true);
        const response = await FNOLService.savePropertyIncident(
            claimNumber,
            propertyIncident,
            authHeader
        );
        setLoadingMask(false);
        if (!isReadOnly) {
            const newClaimVM = viewModelService.clone(claimVM);
            newClaimVM.value = response;
            updateWizardData(newClaimVM);
            updateWizardSnapshot(newClaimVM);
        }
    }, [setLoadingMask, claimNumber, authHeader, isReadOnly, viewModelService, claimVM, updateWizardData, updateWizardSnapshot])

    const removePropertyIncident = useCallback(async (propertyIncident) => {
        setLoadingMask(true);
        const response = await FNOLService.removePropertyIncident(
            claimNumber,
            propertyIncident.publicID,
            authHeader
        );
        setLoadingMask(false);
        if (!isReadOnly) {
            const newClaimVM = viewModelService.clone(claimVM);
            newClaimVM.value = response;
            updateWizardData(newClaimVM);
            updateWizardSnapshot(newClaimVM);
        }
    },[authHeader, claimNumber, claimVM, isReadOnly, setLoadingMask, updateWizardData, updateWizardSnapshot, viewModelService])

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                labelPosition: 'left',
                showOptional: true
            },
            otherVehiclesSection: {
                visible: [ PA_LOB_NAME, CA_LOB_NAME ].includes(LOB_NAME)
            },
            vehicleQuestionToggle: {
                disabled: !_.isEmpty(thirdPartyIncidents),
                value: involvedValue,
                onValueChange: (val) => updateInvolvedValue(val),
            },
            thirdPartyVehicleContainer: {
                visible: involvedValue,
                ...props,
                claimVM,
                LOB_NAME,
                syncWizardData,
                updateIsEditing,
                isEditing,
                basePath: 'vehicles',
                tableData: thirdPartyVehicles,
                incidentType: 'third_party',
                propertyOwnerContacts
            },
            paPropertyTitle: {
                visible: lossCause === 'collision_EXT' || [ GL_LOB_NAME, CPP_LOB_NAME ].includes(LOB_NAME),
                content: [ GL_LOB_NAME, CPP_LOB_NAME ].includes(LOB_NAME) ? 'Property or Vehicle' : 'Property'
            },
            propertyIncidents: {
                propertyIncidents: propertyIncidents,
                predefinedLossLocations,
                incidentsAddresses,
                LOB_NAME,
                visible: lossCause === 'collision_EXT' || [ GL_LOB_NAME, CPP_LOB_NAME ].includes(LOB_NAME),
                propertyOwnerContacts: getInitPropertyOwnerContact(),
                savePropertyIncident,
                removePropertyIncident
            },
            pedestriansSection: {
                visible: [ PA_LOB_NAME, CA_LOB_NAME, RT_LOB_NAME, GL_LOB_NAME, CPP_LOB_NAME ].includes(LOB_NAME)
            },
            paPedestrianTitle: {
                visible: lossCause === 'collision_EXT' || [ GL_LOB_NAME, CPP_LOB_NAME ].includes(LOB_NAME),
            },
            pedestrians: {
                pedestrianIncidents: [ GL_LOB_NAME, CPP_LOB_NAME ].includes(LOB_NAME) ? GLPedestrianIncidents : pedestrianIncidents,
                relatedContacts,
                savePedestrianIncident,
                removePedestrianIncident,
                visible: lossCause === 'collision_EXT' || [ GL_LOB_NAME, CPP_LOB_NAME ].includes(LOB_NAME),
                LOB_NAME
            }
        };
    }, [GLPedestrianIncidents, LOB_NAME, claimVM, getInitPropertyOwnerContact, incidentsAddresses, involvedValue, isEditing, lossCause, pedestrianIncidents, predefinedLossLocations, propertyIncidents, propertyOwnerContacts, props, relatedContacts, removePedestrianIncident, removePropertyIncident, savePedestrianIncident, savePropertyIncident, syncWizardData, thirdPartyIncidents, thirdPartyVehicles])
    

    const handleSaveClaimData = useCallback(() => {
        setLoadingMask(true);
        return FNOLService.saveFNOLDetails(claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                return claimVM;
            })
            .finally(() => {
                setLoadingMask(false);
            });
    }, [authHeader, claimVM, setLoadingMask]);

    const onNext = useCallback(async () => {
        setShowErrors(false);
        if (!isComponentValid) {
            setShowErrors(true);
        }
        setLoadingMask(true);
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const isClaimNumberAvailable = _.get(claimVM.value, 'claimNumber');
        if (isClaimNumberAvailable) {
            return handleSaveClaimData();
        }
        // eslint-disable-next-line no-return-await
        return await FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = new Claim(response);
                updateWizardData(claimVM)
                return claimVM;
            })
            .finally(() => {
                setLoadingMask(false);
            });
    }, [authHeader, claimVM, handleSaveClaimData, isComponentValid, setLoadingMask, updateWizardData]);
    const overrideProps = generateOverrides();
    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const resolvers = {
        resolveComponentMap: {
            losslocationcomponent: FNOLLossLocation,
            PropertyIncidents: PropertyIncidents,
            Pedestrians: Pedestrians
        }
    };

    return (
        <WizardPage
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={onNext}
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

FNOLPAOtherLossDetailPage.propTypes = wizardProps;
FNOLPAOtherLossDetailPage.propTypes = {
};
export default withRouter(withAuthenticationContext(FNOLPAOtherLossDetailPage));
