import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cpRetrieveRiskItemSummaryService'), method, data, additionalHeaders);
}


export default class CPRetrieveRiskItemSummaryService {
    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} buildingPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static retrieveBuildingSummary(jobID , sessionUUID, locationpublicID, buildingPublicID, dto, authHeader = {}) {
        return processSubmission(
            'retrieveBuildingSummary',
            [jobID, sessionUUID, locationpublicID, buildingPublicID, dto],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} buildingPublicID
     * @param {string} businessIncomePublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static retrieveBusinessIncomeSummary(jobID , sessionUUID, locationpublicID, buildingPublicID, businessIncomePublicID, authHeader = {}) {
        return processSubmission(
            'retrieveBusinessIncomeSummary',
            [jobID, sessionUUID, locationpublicID, buildingPublicID, businessIncomePublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyClassPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static retrieveOccupancyClassSummary(jobID , sessionUUID, locationpublicID, buildingPublicID, occupancyClassPublicID, authHeader = {}) {
        return processSubmission(
            'retrieveOccupancyClassSummary',
            [jobID, sessionUUID, locationpublicID, buildingPublicID, occupancyClassPublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyClassPublicID
     * @param {string} personalPropertyPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static retrievePersonalPropertySummary(jobID , sessionUUID, locationpublicID, buildingPublicID, occupancyClassPublicID, personalPropertyPublicID, authHeader = {}) {
        return processSubmission(
            'retrievePersonalPropertySummary',
            [jobID, sessionUUID, locationpublicID, buildingPublicID, occupancyClassPublicID, personalPropertyPublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} specialClassPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static retrieveSpecialClassSummary(jobID , sessionUUID, locationpublicID, specialClassPublicID, authHeader = {}) {
        return processSubmission(
            'retrieveSpecialClassSummary',
            [jobID, sessionUUID, locationpublicID, specialClassPublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} specialClassPublicID
     * @param {string} specClsBIPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static retrieveSpecClsBISummary(jobID , sessionUUID, locationpublicID, specialClassPublicID, specClsBIPublicID, authHeader = {}) {
        return processSubmission(
            'retrieveSpecClsBISummary',
            [jobID, sessionUUID, locationpublicID, specialClassPublicID, specClsBIPublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} buildingPublicID
     * @param {string} displayablePropertyName
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static fetchBuildingClassCodeTable(jobID , sessionUUID, locationpublicID, buildingPublicID, displayablePropertyName, authHeader = {}) {
        return processSubmission(
            'fetchBuildingClassCodeTable',
            [jobID, sessionUUID, locationpublicID, buildingPublicID, displayablePropertyName],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} buildingPublicID
     * @param {string} displayablePropertyName
     * @param {Object} row
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getUpdatedBuildingDetails(jobID , sessionUUID, locationpublicID, buildingPublicID, displayablePropertyName, row, authHeader = {}) {
        return processSubmission(
            'getUpdatedBuilding',
            [jobID, sessionUUID, locationpublicID, buildingPublicID, displayablePropertyName, row],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyClassPublicID
     * @param {string} displayablePropertyName
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static fetchOccupancyClassCodeTable(jobID , sessionUUID, locationpublicID, buildingPublicID, occupancyClassPublicID, displayablePropertyName, authHeader = {}) {
        return processSubmission(
            'fetchOccupancyClassCodeTable',
            [jobID, sessionUUID, locationpublicID, buildingPublicID, occupancyClassPublicID, displayablePropertyName],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyClassPublicID
     * @param {string} displayablePropertyName
     * @param {Object} row
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getUpdatedOccupancyDetails(jobID , sessionUUID, locationpublicID, buildingPublicID, occupancyClassPublicID, displayablePropertyName, row, authHeader = {}) {
        return processSubmission(
            'getUpdatedOccupancy',
            [jobID, sessionUUID, locationpublicID, buildingPublicID, occupancyClassPublicID, displayablePropertyName, row],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} specialClassPublicID
     * @param {string} displayablePropertyName
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static fetchSpecialClassClassCodeTable(jobID , sessionUUID, locationpublicID, specialClassPublicID, displayablePropertyName, authHeader = {}) {
        return processSubmission(
            'fetchSpecialClassClassCodeTable',
            [jobID, sessionUUID, locationpublicID, specialClassPublicID, displayablePropertyName],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationpublicID
     * @param {string} specialClassPublicID
     * @param {string} displayablePropertyName
     * @param {Object} row
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getUpdatedSpecialClassDetails(jobID , sessionUUID, locationpublicID, specialClassPublicID, displayablePropertyName, row, authHeader = {}) {
        return processSubmission(
            'getUpdatedSpecialClass',
            [jobID, sessionUUID, locationpublicID, specialClassPublicID, displayablePropertyName, row],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static onPageNext(jobID , sessionUUID, authHeader = {}) {
        return processSubmission(
            'onPageNext',
            [jobID, sessionUUID],
            authHeader
        );
    }

   /**
    * @function
    * @param {string} jobID 
    * @param {string} sessionUUID 
    * @param {string} locationPublicID
    * @param {string} buildingPublicID
    * @param {Object} dto
    * @param {Object} authHeader
    * @returns {Promise}
    */
   static updateNotRefreshBuildingDetails(jobID , sessionUUID, locationPublicID, buildingPublicID, dto, authHeader = {}) {
       return processSubmission(
           'updateNotRefreshBuildingDetails',
           [jobID, sessionUUID, locationPublicID, buildingPublicID, dto],
           authHeader
       );
   }
}