import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { IMPhysiciansAndSurgeonsService } from 'wni-capability-quoteandbind-im';
import { PortalConstants } from 'wni-portals-config-js';
import { useTranslator } from '@jutro/locale';
import {
    ErrorsAndWarningsUtil,
    // WizardUtil,
    // WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext'
import IMSearchAndAddClausesPopup from '../../components/IMSearchAndAddClausesPopup/IMSearchAndAddClausesPopup';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import WizardPage from '../../templates/IMWizardPage';
import metadata from './IMPhysiciansAndSurgeonsPage.metadata.json5';
import messages from './IMPhysiciansAndSurgeonsPage.messages';

const PHYSIANDSUR_ACCORDION_CARD_COMPONENT_MAPS = ['physiciansAndSurgeonsMainCov', 'coveragePartSchedule']
function IMPhysiciansAndSurgeonsPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        imPhysiciansAndSurgeonsService,
        markFollowingWizardStepsUnvisited,
        readOnly
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            periodStatus
        }
    } = submissionVM.value

    const modalApi = useWniModal();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('IMPhysiciansAndSurgeonsPage');

    const [validationIssues, updateValidationIssues] = useState([]);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const [coveragePartClauses, setCoveragePartClauses] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    const handleValidation = useCallback(() => {
        //
    }, [invalidFields]);

    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus === PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await imPhysiciansAndSurgeonsService.getPhysiciansAndSurgeonsClausesWithSync(jobID, sessionUUID, authHeader)
            : await imPhysiciansAndSurgeonsService.getPhysiciansAndSurgeonsClauses(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setCoveragePartClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID])

    useEffect(() => {
        initCoverablePartClauses()
    }, [])

    const showClausesModal = useCallback((clauseType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            clauseType,
            coverableService: imPhysiciansAndSurgeonsService
        };
        return modalApi.showModal(
            <IMSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, sessionUUID]);

    const onClickAddSearchClauses = useCallback(async (clauseType) => {
        const data = await showClausesModal(clauseType)
        setCoveragePartClauses(data)
    }, [showClausesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    }

    const accordionCommonProps = {
        coveragePartClauses,
        setCoveragePartClauses: (newCoveragePartClauses) => {
            updateWizardDataWhileSetPeriodStatus(submissionVM)
            setCoveragePartClauses(newCoveragePartClauses)
            markFollowingWizardStepsUnvisited()
        },
        isEditing,
        setIsEditing,
        isEditable: !readOnly,
        showErrors,
        onValidate: onValidate,
        loadingClauses: isServiceCallInProgress,
        setLoadingClauses: updateServiceCallInProgress,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        updateCoverageFormClausesService: imPhysiciansAndSurgeonsService.updatePhysiciansAndSurgeonsClauses
    }

    const overrideProps = {
        '@field': {
            isEditable: true,
        },
        coveragesAccordionCard: {
            errorState: !_.isEmpty(_.intersection(invalidFields, PHYSIANDSUR_ACCORDION_CARD_COMPONENT_MAPS))
        },
        physiciansAndSurgeonsMainCov: {
            ...accordionCommonProps,
            mainCoverage: _.get(coveragePartClauses, 'mainCoverages[0]'),
            coverageFormPath: 'mainCoverages[0]'
        },
        coveragePartSchedule: {
            ...accordionCommonProps,
            coveragePartSchedule: _.get(coveragePartClauses, 'coveragePartSchedule'),
            updateScheduleService: imPhysiciansAndSurgeonsService.updateCoveragePartSchedule,
            schedulePath: 'coveragePartSchedule',
            scheduleLable: translator(messages.scheduleLable),
            scheduleFormHeader: translator(messages.scheduleFormHeader),
        },
        additionalCoverages: {
            ...accordionCommonProps,
            coveragePartName: 'physiciansAndSurgeons'
        }
    };

    const onPageNext = useCallback(async () => {
        if (readOnly) {
            return submissionVM;
        }
        setLoadingMask(true)
        const errorsAndWarnings = await imPhysiciansAndSurgeonsService.onPhysiciansAndSurgeonsNext(jobID, sessionUUID, authHeader)
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM;
    }, [authHeader, imPhysiciansAndSurgeonsService, jobID, readOnly, sessionUUID, setLoadingMask, submissionVM, validationIssues]);

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            maincoverage: IMCommonMainCoverage,
            commoncovpartschedule: IMCommonCovPartSchedule,
            commonadditionalcovcard: IMCommonAdditionalCovCard
        }
    };

    const disableNavigator = isEditing || isLoadingMask || isServiceCallInProgress

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {coveragePartClauses &&
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

IMPhysiciansAndSurgeonsPage.propTypes = {
    ...wizardProps,
    imPhysiciansAndSurgeonsService: PropTypes.object
};

IMPhysiciansAndSurgeonsPage.defaultProps = {
    imPhysiciansAndSurgeonsService: IMPhysiciansAndSurgeonsService
};
export default IMPhysiciansAndSurgeonsPage;