import React, {
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { CPRisksService, CPRetrieveRiskItemSummaryService } from 'wni-capability-quoteandbind-cp';
import { useWniModal } from 'wni-components-platform-react';
import { PortalConstants } from 'wni-portals-config-js';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { WniDocumentRetrievalService } from 'wni-capability-gateway';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import moment from 'moment';

import {
    QuoteUtil,
    ValidationIssueUtil
} from 'wni-portals-util-js';

import ExportModal from '../../components/ExportModal/ExportModal';
import CPLocationImportPopup from '../Locations/Components/CPLocaitonImport/CPLocationImportPopup';
import WizardPage from '../../templates/CPWizardPage';
import metadata from './CPRisksPage.metadata.json5';
import messages from './CPRisksPage.messages';
import CPRisksUtil from './CPRisksUtil';
import RiskItemComponent from './component/RiskItemComponent';
import BuildingAndOccupanyInfoPopup from './component/BuildingAndOccupanyInfoPopup/BuildingAndOccupanyInfoPopup';


function CPRisksPage(props) {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        isReadOnly,
        //
        // onPreQuotePageNext,
        isPolicyChange,
    } = props;

    const {
        jobID, 
        sessionUUID,
        lobData: {
            commercialProperty: {
                riskTreeRows = []
            }
        },
    } = submissionVM.value;

    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate
    } = useValidation('CPRisksPage');

    const [showErrors, updateShowErrors] = useState(false);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [selectedRiskItem, setSelectedRiskItem]  = useState();
    const [isShowRiskItem, updateIsShowRiskItem] = useState(true);
    const disableNext = !_.isNil(selectedRiskItem) && (selectedRiskItem.coverableType !== "CP7 Location");
    const [prefilledBuildingDTO, updatePrefilledBuildingDTO] = useState();

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateSubmissionVMAndSetStatusToDraft = useCallback((newSubmissionVM) => {
        const updatedSubmissionVM = setWizardDataToDraft(newSubmissionVM)
        updateSubmissionVM(updatedSubmissionVM)
    }, [setWizardDataToDraft, updateSubmissionVM])

    useEffect(() => {
        const activeCoverableType = selectedRiskItem ? _.get(selectedRiskItem, 'coverableType') : null;
        const activePublicID = selectedRiskItem ? _.get(selectedRiskItem, 'publicID') : null;
        WniTableRowUtil.setTablePublicIDSelected(`${activeCoverableType}${activePublicID}`, "risksTable");
    }, [selectedRiskItem]);

    const validationTree = CPRisksUtil.generateValidationTreeByRowTree(riskTreeRows)

    const getTableData = useCallback(() => {
        const retval = CPRisksUtil.getRisksTableData(riskTreeRows);
        return retval;
    }, [riskTreeRows])
    // const handleValidation = useCallback(() => {
    //     //
    // }, [invalidFields]);

    const getCoverableDetailsAndSubnodes = async(e, item, isCollapsed) => {
        e.stopPropagation();
        setLoadingMask(true);
        let locationIndex = riskTreeRows.findIndex(loc => loc.publicID === item.locationPublicID);
        if (item.coverableType === "CP7 Location") {
            locationIndex = riskTreeRows.findIndex(loc => loc.publicID === item.publicID);
            const locationPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}]`;
            const location = _.get(submissionVM, locationPath, []);
            _.set(location, 'collapsed', !isCollapsed);
            if (isCollapsed) {
                _.set(submissionVM, locationPath, {...location, openBuildingTreeRows: true, openSpecialCLassTreeRows: true});
            } else {
                _.set(submissionVM, locationPath, {...location, openBuildingTreeRows: false, openSpecialCLassTreeRows: false});
            }
        }
        if (item.coverableType === "Building") {
            const buildingIndex = riskTreeRows[locationIndex].buildingTreeRows?.findIndex(elt => elt.publicID === item.publicID);
            const buildingPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}].buildingTreeRows[${buildingIndex}]`;
            const building = _.get(submissionVM, buildingPath, []);
            _.set(building, 'coverableColumnBasicInfo.collapsed', !isCollapsed);
            if (isCollapsed) {
                _.set(submissionVM, buildingPath, {...building, openOccupancyTreeRows: true, openBusinessIncomeRows: true});
            } else {
                _.set(submissionVM, buildingPath, {...building, openOccupancyTreeRows: false, openBusinessIncomeRows: false});
            }
        }
        if (item.coverableType === "Special Class") {
            const specialCLassIndex = riskTreeRows[locationIndex].specialCLassTreeRows?.findIndex(elt => elt.publicID === item.publicID);
            const specialCLassPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}].specialCLassTreeRows[${specialCLassIndex}]`;
            const specialCLass = _.get(submissionVM, specialCLassPath, []);
            _.set(specialCLass, 'coverableColumnBasicInfo.collapsed', !isCollapsed);
            if (isCollapsed) {
                _.set(submissionVM, specialCLassPath, {...specialCLass, openSpecialClassBusinessIncomeTreeRows: true});
            } else {
                _.set(submissionVM, specialCLassPath, {...specialCLass, openSpecialClassBusinessIncomeTreeRows: false})
            }
            
        }
        if (item.coverableType === "Occupancy") {
            const buildingIndex = riskTreeRows[locationIndex].buildingTreeRows?.findIndex(elt => elt.publicID === item.buildingPublicID);
            const buildingPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}].buildingTreeRows[${buildingIndex}]`;
            const building = _.get(submissionVM, buildingPath, []);
            const occupancyIndex = building.occupancyTreeRows?.findIndex(elt => elt.publicID === item.publicID);
            const occupancyPath = `${buildingPath}.occupancyTreeRows[${occupancyIndex}]`;
            const occupancy = _.get(submissionVM, occupancyPath, []);
            _.set(occupancy, 'coverableColumnBasicInfo.collapsed', !isCollapsed);
            if (isCollapsed) {
                _.set(submissionVM, occupancyPath, {...occupancy, openPersonalPropertyTreeRows: true});
            } else {
                _.set(submissionVM, occupancyPath, {...occupancy, openPersonalPropertyTreeRows: false})
            }
            
        }
        updateSubmissionVM(submissionVM);
        setLoadingMask(false);
    }

    const showBuildingOccupancyPopup = useCallback((locationPublicID, buildingPublicID, retrievedLostCostResponses) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            setLoadingMask,
            lossCostResponses: retrievedLostCostResponses,
            jobID,
            sessionUUID,
            authHeader,
            locationPublicID,
            buildingPublicID
        };
        modalApi.showModal(<BuildingAndOccupanyInfoPopup {...componentProps}/>)
            .then((res) => {
                updatePrefilledBuildingDTO(res);
                updateSubmissionVMAndSetStatusToDraft(submissionVM);
                updateIsShowRiskItem(true);
            })
            .catch(() => {
                updateIsShowRiskItem(true);
            });
    }, [modalApi, setLoadingMask, submissionVM, updateSubmissionVMAndSetStatusToDraft, jobID, sessionUUID, authHeader])

    // addd all kinds of risks
    const addBuilding = useCallback(async() => {
        setLoadingMask(true);
        let newLocation;
        if (!_.isNil(selectedRiskItem)) {
            newLocation = await CPRisksService.addLocationGroupCoverable(jobID, sessionUUID, selectedRiskItem.publicID, "Building", authHeader);
            const locationIndex = riskTreeRows.findIndex(loc => loc.publicID === selectedRiskItem.publicID);
            const locationPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}]`;
            const newBuilding = newLocation.buildingTreeRows?.find(elt => elt.publicID === newLocation.newAddedCoverableID);
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, locationPath, newLocation);
            updateSubmissionVMAndSetStatusToDraft(newSubmissionVM)
            setSelectedRiskItem(newBuilding.coverableColumnBasicInfo);
            updateIsShowRiskItem(_.isNil(newLocation.lostCostResponses));
        }
        setLoadingMask(false);
        if (!_.isNil(newLocation.lostCostResponses)) {
            showBuildingOccupancyPopup(newLocation.publicID, newLocation.newAddedCoverableID, newLocation.lostCostResponses);
        }
        
    }, [authHeader, jobID, riskTreeRows, selectedRiskItem, sessionUUID, setLoadingMask, showBuildingOccupancyPopup, submissionVM, updateSubmissionVMAndSetStatusToDraft])

    const addSpecialClass = useCallback(async() => {
        setLoadingMask(true);
        if (!_.isNil(selectedRiskItem)) {
            const newLocation = await CPRisksService.addLocationGroupCoverable(jobID, sessionUUID, selectedRiskItem.publicID, "Special Class", authHeader);
            const locationIndex = riskTreeRows.findIndex(loc => loc.publicID === selectedRiskItem.publicID);
            const locationPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}]`;
            const newSpecialClass = newLocation.specialCLassTreeRows?.find(elt => elt.publicID === newLocation.newAddedCoverableID);
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, locationPath, newLocation);
            updateSubmissionVMAndSetStatusToDraft(newSubmissionVM)
            setSelectedRiskItem(newSpecialClass.coverableColumnBasicInfo);
        }
        setLoadingMask(false);
    }, [authHeader, jobID, riskTreeRows, selectedRiskItem, sessionUUID, setLoadingMask, submissionVM, updateSubmissionVMAndSetStatusToDraft])

    const addOccupancy = useCallback(async() => {
        setLoadingMask(true);
        if (!_.isNil(selectedRiskItem)) {
            const newLocation = await CPRisksService.addBuildingGroupCoverable(jobID, sessionUUID, selectedRiskItem.locationPublicID, selectedRiskItem.publicID, "Occupancy", authHeader);
            const locationIndex = riskTreeRows.findIndex(loc => loc.publicID === selectedRiskItem.locationPublicID);
            const locationPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}]`;

            const buildingIndex = riskTreeRows[locationIndex].buildingTreeRows?.findIndex(elt => elt.publicID === selectedRiskItem.publicID);
            const newOccupancies = _.get(newLocation, `buildingTreeRows[${buildingIndex}].occupancyTreeRows`, []);
            const newOccupancy = newOccupancies?.find(elt => elt.publicID === newLocation.newAddedCoverableID);
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, locationPath, newLocation);
            updateSubmissionVMAndSetStatusToDraft(newSubmissionVM)
            setSelectedRiskItem(newOccupancy.coverableColumnBasicInfo);
        }
        setLoadingMask(false);
    }, [authHeader, jobID, riskTreeRows, selectedRiskItem, sessionUUID, setLoadingMask, submissionVM, updateSubmissionVMAndSetStatusToDraft])

    const addPersonalProperty = useCallback(async() => {
        setLoadingMask(true);
        if (!_.isNil(selectedRiskItem)) {
            const newLocation = await CPRisksService.addPersonalProperty(jobID, sessionUUID, selectedRiskItem.locationPublicID, selectedRiskItem.buildingPublicID, selectedRiskItem.publicID, authHeader);
            const locationIndex = riskTreeRows.findIndex(loc => loc.publicID === selectedRiskItem.locationPublicID);
            const locationPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}]`;

            const buildingIndex = riskTreeRows[locationIndex].buildingTreeRows?.findIndex(elt => elt.publicID === selectedRiskItem.buildingPublicID);
            const buildingPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}].buildingTreeRows[${buildingIndex}]`;
            const building = _.get(submissionVM, buildingPath, []);

            const occupancyIndex = building.occupancyTreeRows?.findIndex(elt => elt.publicID === selectedRiskItem.publicID);

            const newPersonalProperties = _.get(newLocation,  `buildingTreeRows[${buildingIndex}].occupancyTreeRows[${occupancyIndex}].personalPropertyTreeRows`, []);
            const newPersonalProperty = newPersonalProperties?.find(elt => elt.publicID === newLocation.newAddedCoverableID);
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, locationPath, newLocation);
            updateSubmissionVMAndSetStatusToDraft(newSubmissionVM)
            setSelectedRiskItem(newPersonalProperty.coverableColumnBasicInfo);
        }
        setLoadingMask(false);
    }, [authHeader, jobID, riskTreeRows, selectedRiskItem, sessionUUID, setLoadingMask, submissionVM, updateSubmissionVMAndSetStatusToDraft])

    const addBusinessIncome = useCallback(async() => {
        setLoadingMask(true);
        if (!_.isNil(selectedRiskItem)) {
            const newLocation = await CPRisksService.addBuildingGroupCoverable(jobID, sessionUUID, selectedRiskItem.locationPublicID, selectedRiskItem.publicID, "Business Income", authHeader);
            const locationIndex = riskTreeRows.findIndex(loc => loc.publicID === selectedRiskItem.locationPublicID);
            const locationPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}]`;

            const buildingIndex = riskTreeRows[locationIndex].buildingTreeRows?.findIndex(elt => elt.publicID === selectedRiskItem.publicID);

            const newBusinessIncomes = _.get(newLocation, `buildingTreeRows[${buildingIndex}].businessIncomeRows`, []);
            const newBusinessIncome = newBusinessIncomes?.find(elt => elt.publicID === newLocation.newAddedCoverableID);
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, locationPath, newLocation);
            updateSubmissionVMAndSetStatusToDraft(newSubmissionVM)
            setSelectedRiskItem(newBusinessIncome.coverableColumnBasicInfo);
        }
        setLoadingMask(false);
    }, [authHeader, jobID, riskTreeRows, selectedRiskItem, sessionUUID, setLoadingMask, submissionVM, updateSubmissionVMAndSetStatusToDraft])

    const addSpecialClassBusinessIncome = useCallback(async() => {
        setLoadingMask(true);
        if (!_.isNil(selectedRiskItem)) {
            const newLocation = await CPRisksService.addSpecialClassBusinessIncome(jobID, sessionUUID, selectedRiskItem.locationPublicID, selectedRiskItem.publicID, authHeader);
            const locationIndex = riskTreeRows.findIndex(loc => loc.publicID === selectedRiskItem.locationPublicID);
            const locationPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}]`;

            const specialCLassIndex = riskTreeRows[locationIndex].specialCLassTreeRows?.findIndex(elt => elt.publicID === selectedRiskItem.publicID);

            const newSpecClsBIs = _.get(newLocation, `specialCLassTreeRows[${specialCLassIndex}].specialClassBusinessIncomeTreeRows`, []);
            const newSpecClsBI = newSpecClsBIs?.find(elt => elt.publicID === newLocation.newAddedCoverableID);
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, locationPath, newLocation);
            updateSubmissionVMAndSetStatusToDraft(newSubmissionVM)
            setSelectedRiskItem(newSpecClsBI.coverableColumnBasicInfo);
        }
        setLoadingMask(false);
    }, [authHeader, jobID, riskTreeRows, selectedRiskItem, sessionUUID, setLoadingMask, submissionVM, updateSubmissionVMAndSetStatusToDraft])


    const redirectToCurrentLocation = useCallback((newLocation) => {
        const locationIndex = riskTreeRows.findIndex(loc => loc.publicID === selectedRiskItem.locationPublicID);
        const locationPath = `value.lobData.commercialProperty.riskTreeRows[${locationIndex}]`;
        _.set(submissionVM, locationPath, newLocation);
        setSelectedRiskItem(newLocation);
        updateSubmissionVMAndSetStatusToDraft(submissionVM);
    }, [riskTreeRows, selectedRiskItem, submissionVM, updateSubmissionVMAndSetStatusToDraft]);

    const handleDeleteItem = useCallback(() => {
        modalApi.showConfirm({
                title: messages.deleteRiskTitle,
                message: messages.deleteRiskInfo,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.modalOk,
                cancelButtonText: messages.modalCancel,
            }).then((results) => {
                if (results === 'confirm') {
                    if (selectedRiskItem.coverableType === "Building") {
                        setLoadingMask(true);
                        CPRisksService.removeBuilding(
                            jobID, 
                            sessionUUID, 
                            selectedRiskItem.locationPublicID, 
                            selectedRiskItem.publicID, 
                            authHeader).then((newLocation) => {
                                redirectToCurrentLocation(newLocation);
                                setLoadingMask(false);
                            });
                    }
                    if (selectedRiskItem.coverableType === "Special Class") {
                        setLoadingMask(true);
                        CPRisksService.removeSpecialClass(
                            jobID, 
                            sessionUUID, 
                            selectedRiskItem.locationPublicID, 
                            selectedRiskItem.publicID, 
                            authHeader).then((newLocation) => {
                                redirectToCurrentLocation(newLocation);
                                setLoadingMask(false);
                            });
                    }
                    if (selectedRiskItem.coverableType === "Occupancy") {
                        setLoadingMask(true);
                        CPRisksService.removeOccupancy(
                            jobID, 
                            sessionUUID, 
                            selectedRiskItem.locationPublicID, 
                            selectedRiskItem.buildingPublicID,
                            selectedRiskItem.publicID, 
                            authHeader).then((newLocation) => {
                                redirectToCurrentLocation(newLocation);
                                setLoadingMask(false);
                            });
                    }
                    if (selectedRiskItem.coverableType === "Business Income") {
                        setLoadingMask(true);
                        CPRisksService.removeBusinessIncome(
                            jobID, 
                            sessionUUID, 
                            selectedRiskItem.locationPublicID, 
                            selectedRiskItem.buildingPublicID,
                            selectedRiskItem.publicID, 
                            authHeader).then((newLocation) => {
                                redirectToCurrentLocation(newLocation);
                                setLoadingMask(false);
                            });
                    }
                    if (selectedRiskItem.coverableType === "Special Class Business Income") {
                        setLoadingMask(true);
                        CPRisksService.removeSpecialClassBusinessIncome(
                            jobID, 
                            sessionUUID, 
                            selectedRiskItem.locationPublicID, 
                            selectedRiskItem.specialClassPublicID,
                            selectedRiskItem.publicID, 
                            authHeader).then((newLocation) => {
                                redirectToCurrentLocation(newLocation);
                                setLoadingMask(false);
                            });
                    }
                    if (selectedRiskItem.coverableType === "Personal Property") {
                        setLoadingMask(true);
                        CPRisksService.removePersonalProperty(
                            jobID, 
                            sessionUUID, 
                            selectedRiskItem.locationPublicID, 
                            selectedRiskItem.buildingPublicID,
                            selectedRiskItem.occupancyPublicID,
                            selectedRiskItem.publicID, 
                            authHeader).then((newLocation) => {
                                redirectToCurrentLocation(newLocation);
                                setLoadingMask(false);
                            });
                    }
                    return true;
                }
            }, _.noop);
    }, [authHeader, jobID, modalApi, redirectToCurrentLocation, selectedRiskItem, sessionUUID, setLoadingMask]);


    /**
     * 
     * @param {(oldRiskTreeRow: Array<Object>) => newRiskTreeRow: Array<Object>} generateNewRiskTreeRowFunc 
     */
    const updateNewRiskTreeRow = useCallback((generateNewRiskTreeRowFunc) => {
        const newRiskTreeRow = generateNewRiskTreeRowFunc(riskTreeRows)
        const newSubmissionVM = _.clone(submissionVM)
        _.set(newSubmissionVM.value, 'lobData.commercialProperty.riskTreeRows', newRiskTreeRow)
        updateSubmissionVMAndSetStatusToDraft(newSubmissionVM)
    }, [riskTreeRows, submissionVM, updateSubmissionVMAndSetStatusToDraft])
    

    const renderDescriptionCell = CPRisksUtil.getRenderDescriptionCellFuncForCPRisksPage(validationTree, getCoverableDetailsAndSubnodes);

    //
    const importData = useCallback(() => {
        const componentProps = {
            triggerScreen: 'CPRisk',
            showNote: false,
            size: 'md',
            actionBtnLabel: translator(commonMessages.ok),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            extendProps: {
                jobID,
                sessionUUID,
                authHeader,
            },
        };
        modalApi
            .showModal(<CPLocationImportPopup {...componentProps} />)
            .then((result) => {
                _.set(
                    submissionVM.value,
                    'lobData.commercialProperty.riskTreeRows',
                    result
                );
                updateSubmissionVMAndSetStatusToDraft(submissionVM);
                setSelectedRiskItem(null);
            })
            .catch(() => {
                // do nothing when close the popup
                _.noop();
            });
    }, [
        authHeader,
        jobID,
        modalApi,
        sessionUUID,
        submissionVM,
        translator,
        updateSubmissionVMAndSetStatusToDraft,
    ]);

    const displayErrorModal = useCallback(() => {
        modalApi.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, [modalApi]);

    const download = useCallback((formatName, exportType) => {
        const date = moment(new Date()).format('YYYYMMDD-hhMM');
        const serviceUrl = getProxiedServiceUrl('cpDownloadService').concat(`/${jobID}?jobID=${jobID}&exportType=${exportType}&formatName=${formatName}`);
        let fileName =`Submission_${jobID}_CP7_Buildings_${date}`;
        if(exportType === "SPCLs"){
            fileName =`Submission_${jobID}_CP7_Special_Class_${date}`;
        }
        // Submission_1061153772_CP7_Locations_20240729-0145
        return WniDocumentRetrievalService.generateDocument(serviceUrl, fileName, authHeader, _.noop, displayErrorModal)
    }, [authHeader, displayErrorModal, jobID]);

    const exportRisk = useCallback(async (exportType) => {
        const cpExportMask = await CPRisksService.getExportFormat(
            jobID,
            sessionUUID,
            exportType,
            authHeader
        );
        const componentProps = {
            title: messages.exportTitle,
            actionBtnLabel: translator(messages.exportToSpreadsheet),
            cancelBtnLabel: translator(commonMessages.cancelModel),
            download,
            cpExportMask,
            exportType,
            extendProps: {
                jobID,
                sessionUUID,
                authHeader,
            },
        };
        modalApi
            .showModal(<ExportModal {...componentProps} />)
            .then(() => _.noop)
            .catch(() => _.noop);
    }, [authHeader, download, jobID, modalApi, sessionUUID, translator]);

    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: isReadOnly
            },
            '@field': {
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            importData: {
                visible: !isReadOnly
            },
            exportData: {
                visible: !isReadOnly
            },
            exportBuilding: {
                onClick: () => {
                    exportRisk('Buildings');
                },
            },
            exportSPCLs: {
                onClick: () => {
                    exportRisk('SPCLs');
                },
            },
            risksTable: {
                data: getTableData(),
                onRowClick: (item) => {
                    if (_.isNil(selectedRiskItem) || (selectedRiskItem.coverableType === "CP7 Location"))
                        {setSelectedRiskItem(item)}
                    }
            },
            riskItemComponent: {
                riskItem: selectedRiskItem,
                setSelectedRiskItem: setSelectedRiskItem,
                jobID,
                sessionUUID,
                authHeader,
                submissionVM,
                updateSubmissionVM: updateSubmissionVMAndSetStatusToDraft,
                updateNewRiskTreeRow,
                isReadOnly,
                visible: isShowRiskItem,
                prefilledBuildingDTO,
                updatePrefilledBuildingDTO
            },
            delRisks: {
                onClick: handleDeleteItem,
                disabled: _.isNil(selectedRiskItem) || (_.get(selectedRiskItem,'coverableType','') === 'CP7 Location'),
                visible: !isReadOnly
            },
            addBuilding: {
                visible : (_.get(selectedRiskItem, 'coverableType', '') === 'CP7 Location') && !isReadOnly,
                onClick: addBuilding, 
            },
            addSpecialClass: {
                visible : (_.get(selectedRiskItem, 'coverableType', '') === 'CP7 Location') && !isReadOnly,
                onClick: addSpecialClass,
            },
            addOccupancy: {
                visible : ((_.get(selectedRiskItem, 'coverableType', '') === 'Building') && CPRisksUtil.canAddOccupancy(selectedRiskItem)) && !isReadOnly,
                onClick: addOccupancy,
            },
            addBusinessIncome: {
                visible : (_.get(selectedRiskItem, 'coverableType', '') === 'Building') && !isReadOnly,
                onClick: addBusinessIncome
            },
            addPersonalProperty: {
                visible : (_.get(selectedRiskItem, 'coverableType', '') === 'Occupancy') && !isReadOnly,
                onClick: addPersonalProperty
            },
            addSpecialClassBusinessIncome: {
                visible : (_.get(selectedRiskItem, 'coverableType', '') === 'Special Class') && !isReadOnly,
                onClick: addSpecialClassBusinessIncome
            }
        };
    }, [addBuilding, addBusinessIncome, addOccupancy, addPersonalProperty, addSpecialClass, addSpecialClassBusinessIncome, authHeader, exportRisk, getTableData, handleDeleteItem, isReadOnly, isShowRiskItem, jobID, prefilledBuildingDTO, selectedRiskItem, sessionUUID, submissionVM, updateNewRiskTreeRow, updateSubmissionVMAndSetStatusToDraft]);

    const onPageNext = useCallback(async () => {
        if (!validationTree.isValid) {
            return false
        }
        if (isReadOnly) {
            return submissionVM;
        }

        const {
            onPreQuotePageNext, // = onDefaultPageNext,
        } = props;

        const res = await CPRetrieveRiskItemSummaryService.onPageNext(jobID, sessionUUID, authHeader);
        const errorsAndWarnings = _.get(res, 'errorsAndWarnings');
        const blankets = _.get(res, 'blankets');
        if (!_.isNil(blankets)) {
            _.set(submissionVM.value, 'lobData.commercialProperty.blankets', blankets);
        }
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(errorsAndWarnings);
        updateValidationIssues(newValidationIssues);
        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            // WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }

        //
        let retval = submissionVM;
        if (_.isFunction(onPreQuotePageNext)) {
            retval = await onPreQuotePageNext();
        }
        return retval;
    }, [authHeader, displayWarnings, isReadOnly, jobID, props, sessionUUID, submissionVM, validationTree.isValid]);

    // //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
            renderDescriptionCell,
            importData
        },
        resolveComponentMap: {
            RiskItemComponent: RiskItemComponent
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            disableNext={disableNext}
            pageLevelValidationIssues={validationIssues}
            isPolicyChange={isPolicyChange}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

CPRisksPage.propTypes = {
   ...wizardProps,
   isReadOnly: PropTypes.bool
};
CPRisksPage.defaultProps = {
    isReadOnly: false
};

export default CPRisksPage;