import _ from 'lodash';


const AGENT_BR_WARNING_LIST = [ // reason for BR.CL.0486 & 0488
    'There is an active Umbrella policy on this account, please review if this new policy needs to be added to the Umbrella underlying schedule', 
    'Changes on underlying policies will require updating Umbrella'
]

const changeAgentWarningBRTypeFn = (allIssues = []) => {
    const updatedIssues = allIssues.map(issue => {
        if (AGENT_BR_WARNING_LIST.includes(issue.reason)){
            _.set(issue, 'type', 'agentWarning')
        }
        return issue
    });
    if (updatedIssues){
        return updatedIssues
    }
    return []
};


export default {
    changeAgentWarningBRTypeFn
}